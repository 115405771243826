import React from 'react'

import * as Icons from 'react-icons/ri'

function TablePagination(props) {
  return (
    <div className='flex justify-center items-center whitespace-nowrap font-sora w-full mt-8 pl-4 md:px-6'>
                    
                <div className="w-full justify-between items-center flex">
                <span className=' text-primary-grey text-sm font-sora'>
                        
                        <span className='mr-2'>
                            {"Page "}
                            {props.current_page}
                            {' '} of {props.last_page}
                        </span>{' '}
                    </span>
                    {' '}
                    <div className='justify-end bg-light-purple items-center flex font-sora '>
                        <button className={`${props.prev_page_url !== null ? 'bg-primary-blue' : 'bg-light-purple'} p-1 px-2 inline-flex justify-center items-center`} 
                        onClick={() => props.pageChangeHandler(-1)} >
                            <Icons.RiArrowDropLeftLine className={`${props.prev_page_url !== null ? 'text-white' : 'text-table-gray'} text-xl font-medium`}/>
                            <p className={`${props.prev_page_url !== null ? 'text-white' : 'text-table-gray'} mr-1 text-sm`}>Prev</p>
                            
                        </button>{' '}

                        {/* {
                            props.pages.map((option, index) => {
                                if(option.label === "&laquo; Previous" || option.label === "Next &raquo;"){
                                    return (
                                        <div key={index}>{""}</div>
                                    )
                                }
                                return(
                                    <React.Fragment key={index}>
                                    <button  className={`${option.active === true ? 'bg-primary-blue' : 'bg-table-gray'} 
                                    p-1 px-3 text-white inline-flex justify-center items-center`} 
                                    onClick={() => props.pageChangeHandler(option.url)}>
                                        {option.label}
                                    </button>{' '}
                                    </React.Fragment>
                                )
                            })
                        } */}
                        
                        <button className={`${props.next_page_url !== null ? 'bg-primary-blue' : 'bg-light-purple'} p-1 px-2 inline-flex justify-center items-center`} 
                        onClick={() => props.pageChangeHandler(1)} >
                            <p className={`${props.next_page_url !== null ? 'text-white' : 'text-table-gray'} mr-1 text-sm`}>Next</p>
                            <Icons.RiArrowDropRightLine className={`${props.next_page_url !== null ? 'text-white' : 'text-table-gray'} text-xl font-medium`}/>
                        </button>    
                    </div>{' '}

                    
                </div>
            </div>
  )
}

export default TablePagination