import React from 'react'

function PillTab(props) {

  const {color, className, text} = props

  if(color === "green"){
    return (
      <div onClick={props.onClick} className={`text-xs cursor-pointer font-light px-6 py-3 w-fit rounded-md text-center 
      bg-primary-green text-white green ${className}`}>
          {text}
      </div>
    )
  }
  if(color === "yellow"){
    return (
      <div onClick={props.onClick} className={`text-xs cursor-pointer font-light px-6 py-3 w-fit rounded-md text-center 
      bg-primary-yellow text-white yellow ${className}`}>
          {text}
      </div>
    )
  }
  if(color === "red"){
    return (
      <div onClick={props.onClick} className={`text-xs cursor-pointer font-light px-6 py-3 w-fit rounded-md text-center 
      bg-primary-red text-white red ${className}`}>
          {text}
      </div>
    )
  }
  return (
    <div onClick={props.onClick} className={`text-xs cursor-pointer font-light px-6 py-3 w-fit rounded-md text-center 
    bg-primary-${color} text-white ${color} ${className}`}>
        {text}
    </div>
  )
}

export default PillTab