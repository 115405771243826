import React from 'react'

//custom components
import HOC from '../../components/hoc/Hoc'

//sections
import FirstSection from './FirstSection'





function Events() {
  
  return (
    <HOC className='w-full min-h-screen bg-dashboard-bg'>

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col md:flex-row 
        gap-2 md:gap-0 justify-between items-start md:items-center`}>
          <div>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-primary-grey'>
              All Events
            </h1>
            <p className='text-secondary-grey text-sm md:text-base '>View all event list and manage them</p>
          </div>
        </div>

        <FirstSection/> 
        
    </HOC>
  )
}

export default Events