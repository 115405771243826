import React, {useContext, useState} from 'react'
import {useProfile} from '../../api/profile-hook'
import Modal from '../../components/Modal/Modal'

import { images } from '../../../../assets'
import PhotoCard from './components/PhotoCard'
import {useDropzone} from 'react-dropzone';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { DataContext } from '../../../../context/DataContext'





  const initialValues = {
    image:[]
  };

  const validationSchema = Yup.object({
    image: Yup.array().min(1, 'Please drop an image').required('Please drop an image'),
  });

function PhotoUpload(props) {

  const [progress, setProgress] = useState(0);
  const { profileData, updateImageData} = useProfile()

  const dataRes = useContext(DataContext)

  const handleFileUpload = async (file) => {
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'paysocial-admin');

    try {
      const response = await axios.post("https://api.cloudinary.com/v1_1/dj6erxpj5/auto/upload",formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
      
      return response.data

    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async(values) => {
    handleFileUpload(values.image[0]).then((res) => {
      
      const formData = new FormData();
      formData.append("admin_id",dataRes.userData?.id.toString())
      formData.append('image', res.secure_url);

      return updateImageData(formData)

    }).then((res) => {
      
      dataRes.UpdateData(res)
      profileData()
      props.onCancel()

    }).catch((error) => {
      console.log(error)
    })
  }

  const onDrop = (acceptedFiles) => {
      console.log(acceptedFiles)
      formik.setFieldValue('image', acceptedFiles);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1,
    multiple: false, accept: 'image/png, image/jpeg, image/jpg'
  });

  const onDeleteHandler = () => {
    formik.setFieldValue("image", [])
  }

  return (
    <form onSubmit={formik.handleSubmit}>
    <Modal show={props.show} onCancel={props.onCancel} buttonType="submit" SubmitText="Save" CancelText="Cancel" >
        <div className='mb-6 mt-4'>
            <h3 className='font-semibold'>Upload and attach files</h3>
            <p className='text-sm'>Upload your profile picture.</p>
        </div>
        
        {
          formik.values.image.length === 0 ? (
            <>
              {/* Images drop zone section */}
              <div {...getRootProps()} className='mb-6 web-border-8 border border-border-color border-dashed p-2'
                style={{
                  backgroundColor: isDragActive ? '#f5f5f5' : '#fff',
                  }}>
                  <div className='flex flex-col justify-center items-center'>
                    <img src={images.dropImageIcon[0]} alt={images.dropImageIcon[1]} className="object-contain mx-auto mb-2"/>

                  
                    <input  {...getInputProps()} type="file" className='h-0 w-0' id='upload-photo'/>
                    <label htmlFor="upload-photo" className='text-sm text-primary-blue mr-1 font-medium'>
                      Click to upload
                      <span className='text-primary-black-70 font-light text-sm'> or drag and drop</span>
                    </label>
                    
                  </div>
                  <p className='text-primary-black-70 text-xs text-center font-light'>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                </div>
            </>
          ) : (
            <>
              {/* Progress bar section */}
              <div className='web-border-8 border border-border-color border-dashed p-2 lg:p-3'>
                <PhotoCard onDelete={onDeleteHandler} file={formik.values.image[0]} progress={progress}/>
              </div>
            </>
          )
        }

        
        
    </Modal>
    </form>
  )
}

export default PhotoUpload