import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

// installed packages
import AOS from "aos";
import { ToastContainer } from 'react-toastify';

//custom packages css
import 'react-toastify/dist/ReactToastify.css';
import "aos/dist/aos.css";

// route components
import Dashboard from './pages/dashboard/pages/Index';
import auth from './auth'

// custom components
import ScrollToTop from './util/ScrollToTop';

// custom hooks
import { useAuth } from './hooks/auth-hook';
import { AuthContext } from './context/auth-context';


import "./App.css"
import { useData } from './hooks/data-hook';
import { DataContext } from './context/DataContext';
import Loader from './pages/dashboard/UI_Elements/Loader/Loader';

function App() {

  useEffect(() => {
      AOS.init({duration: 2000});
      AOS.refresh();
      
  }, []);

    const { token, login, logout } = useAuth();

    const {userData, updateData, dataLoading} = useData(token ? true : false)

  let routes;

  if (token) {
      
      routes = (
          <Routes>
            <Route path='/dashboard/*' element={<Dashboard/>} />
            <Route path='*' element={<Dashboard/>} />
          </Routes>
      );
  } else {
      routes = (
          <Routes>
                {/* 
                
                <Route path='verification' exact element={< auth.Verification />}/>
                
                <Route path='/signup' exact element={< auth.Signup />}/> */}
                <Route path='newpassword' exact element={< auth.NewPassword />}/>
                <Route path='setpassword' exact element={< auth.CreatePassword />}/>
                <Route path='forgotpassword' exact element={< auth.Forgotpassword />}/>
                <Route path='/login' exact element={< auth.Login />}/>
                {/* <Route path='/' exact element={<Index/>}/> */}
                <Route path="*" element={< auth.Login />}/>
          </Routes>
      );
  }

  return (
      <>
        <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            login: login,
            logout: logout
        }}>
          <DataContext.Provider value={
          {
            userData:userData,
            updateData:updateData
          }
        }>
          {dataLoading && <Loader/>}
          <ToastContainer colored/>
            <Router>
              <ScrollToTop>
                {routes}
              </ScrollToTop>
            </Router>
        </DataContext.Provider>
        </AuthContext.Provider>
      </>
  );
}

export default App;
