import React from 'react'
import Password from './Password';
import Profile from './Profile';

function FirstSection(props) {

  return (
    <div>   

        {props.settings && (
          <div className=' group-border p-2 md:p-4 bg-white'>
            
          {props.settings && <Profile/>}

          </div> 

          
        )}

        {props.password && (
          <div className=' group-border p-2 md:p-4 bg-white'>
            
            {props.password && <Password/>}

          </div> 

          
        )}
        {/* <div className=' group-border p-2 md:p-4 bg-white'>
            
            {props.settings && <Profile/>}
            {props.password && <Password/>}

        </div> 

        <div className='w-full mt-6 flex justify-end'>
          <Button bgPrimary textWhite shadow  className={`ml-auto `}>
              Save Changes
          </Button>
        </div> */}


    </div>
  )
}

export default FirstSection