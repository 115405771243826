export const DASHBOARD_COLUMNS = [
    {
      Header: 'Transaction Id',
      accessor: 'transactionId',
    },
    {
      Header: 'Event Name',
      accessor: 'eventName',
    },
    {
      Header: 'Ticket',
      accessor: 'ticket',
    },
    {
      Header: 'Paid',
      accessor: 'paid',
    },
    {
      Header: 'Commission',
      accessor: 'commission',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Date Purchase',
      accessor: 'datePurchase',
    }
  ]
  


  export const ORDERS_COLUMNS = [
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
    },
    {
      Header: 'Event Name',
      accessor: 'eventName',
    },
    {
      Header: 'Event Date',
      accessor: 'eventDate',
    },
    {
      Header: 'Amount Paid',
      accessor: 'amountPaid',
    },
    {
      Header: 'Ticket',
      accessor: 'ticket',
    }
  ]

  export const BUSINESS_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Status',
      accessor: 'status',
    }
  ]

  export const HISTORY_COLUMNS = [
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Status',
      accessor: 'status',
    }
  ]

  export const STAFF_COLUMNS = [
    // {
    //   Header: 'USER ID',
    //   accessor: 'userId',
    // },
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
    },
    {
      Header: 'ROLE ACCESS',
      accessor: 'roleAccess',
    }
  ]

  export const USER_COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'name',
    },
    {
      Header: 'GENDER',
      accessor: 'gender',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
    },
    {
      Header: 'ADDRESS',
      accessor: 'address',
    },
    {
      Header: 'CITY',
      accessor: 'city',
    },
    {
      Header: 'VERIFIED_EMAIL',
      accessor: 'verifiedEmail',
    },
    {
      Header: 'VERIFIED_PHONE',
      accessor: 'verifiedPhone',
    }
  ]

  export const EVENT_COLUMNS = [
    {
      Header: 'Event Name',
      accessor: 'eventName',
    },
    {
      Header: 'Event Date',
      accessor: 'eventDate',
    },
    {
      Header: 'Time',
      accessor: 'time',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Date Created',
      accessor: 'dateCreated',
    },
    {
      Header: 'Ticket',
      accessor: 'ticket',
    }
  ]

