import React from 'react'
import { useState } from 'react';

import FirstSection from './FirstSection'
import HOC from '../../components/hoc/Hoc';

function Settings() {

  const [settings, setSettings] = useState(true);
  const [password, setPassword] = useState(false);


  const settingsHandler = () =>{
    if(settings){
      return
    }

    setSettings(true)
    setPassword(false)
    
  }

  const passwordHandler = () =>{
    if(password){
      return
    }

    setPassword(true)
    setSettings(false)
    
  }


  return (
    <HOC className='w-full min-h-screen bg-dashboard-bg'>
        <div className='my-2 md:my-6 mb-8 md:mb-10'>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-primary-grey'>
              Settings
            </h1>
            <p className='text-secondary-grey '>Manage account settings</p>
        </div>
        {/* <h1 className='text-xl md:text-2xl lg:text-3xl text-primary-black my-2 md:my-4'>Settings</h1> */}

        {/* different account settings */}
        <div className='my-8 mt-6 flex whitespace-nowrap justify-start items-center scrollbar-none'>

            <button 
            onClick={settingsHandler}
            className={`p-2 px-4  border-b border-transparent ${settings ? 'border-primary-blue font-semibold ' : 'text-primary-black-70 font-normal'}`}>
                Personal
            </button>

            <button 
            onClick={passwordHandler}
            className={`p-2 px-4  border-b border-transparent ${password ? 'border-primary-blue font-semibold ' : 'text-primary-black-70 font-normal'}`}>
                Password
            </button>


        </div>

        <FirstSection settings={settings} password={password}/>
        
    </HOC>
  )
}

export default Settings