import React, {useEffect, useState} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

//custom components
import HOC from '../../components/hoc/Hoc'
import CardHoc from '../../components/card/CardHoc'

//sections
import WarningModal from '../../components/Modal/WarningModal'
import NotificationModal from '../../components/Modal/NotificationModal'

//custom icons
import {IoChevronBack} from 'react-icons/io5'

import './payments.css'
import { useApi } from '../../api/api-hook'
import { businessEndpoints } from '../../api'
import Loader from '../../../../UI_Elements/loader/Loader'



function BusinessDetails() {

    const {id} = useParams()

    const navigate = useNavigate()

  const [typeNotification, setTypeNotification] = useState("")
  const [businessDetails, setBusinessDetails] = useState(null)
    const {getData, postData, isLoading, error} = useApi();

    useEffect(() => {
        const fetchData = async() => {
            
            try{
                const response = await getData(businessEndpoints['ORGANISERS-DETAILS'], null, `?organiser_id=${id}`)
                if(response){
                    
                    setBusinessDetails(response.data.organiser)
                }
            }
            catch(err){
                console.log(error)
            }
        }

        fetchData()
    },[getData, error, id, typeNotification])
  
  const handleNotification = (type) => {
    setTypeNotification(type)
  }

  const approveSubmit = async() => {
    try{

        const formData = new FormData()
        formData.append("organiser_id", id)
        const responseData = await postData(businessEndpoints['APPROVE-ORGANISER'], formData, "")

        if(responseData.status === "success"){
            handleNotification("approve")
            navigate("/dashboard/organizers")
        }

    }
    catch(err) {
        console.log(error)
    }
  }

  const disapproveSubmit = async() => {
    try{

        const formData = new FormData()
        formData.append("organiser_id", id)
        const responseData = await postData(businessEndpoints['SUSPEND-ORGANISER'], formData, "")

        console.log(responseData)

        if(responseData){
            handleNotification("")
            navigate("/dashboard/organizers")
        }

    }
    catch(err) {
        console.log(error)
    }
  }

  const unsuspendSubmit = async() => {
    try{

        const formData = new FormData()
        formData.append("organiser_id", id)
        const responseData = await postData(businessEndpoints['UNSUSPEND-ORGANISER'], formData, "")

        if(responseData.status === "success"){
            handleNotification("approve")
            navigate("/dashboard/organizers")
        }

    }
    catch(err) {
        console.log(error)
    }
  }


  return (
    <>

        
        <NotificationModal show={typeNotification === "approve"} 
        onCancel={() => handleNotification("")} 
        buttonClass="w-full bg-primary-blue text-white" 
        CancelText="OK" textWhite
        title={"Successful"}
        text={`The user account has been approved, the user can now log in`}/>

        <WarningModal 
        show={typeNotification === "disapprove"} 
        onCancel={() => handleNotification("")} 
        onSubmitButton={() => disapproveSubmit()}
        title={"Suspend User"} CancelText="Cancel" SubmitText="Yes"
        text={`Are you sure you want to suspend the user account ?`}/>
    
    <HOC className='w-full min-h-screen bg-dashboard-bg'>

        

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col
        gap-2 items-start`}>
            <Link  to="/dashboard/organizers">
                <IoChevronBack/>
            </Link>
          <div>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-primary-grey'>
            Organzier details
            </h1>
            <p className='text-secondary-grey text-sm md:text-base '>View details of Organzier</p>
          </div>
        </div>


        <CardHoc className="grid grid-cols-1 w-full xl:w-4/5">
            {/* Seller's Details First Section */}
            
            <div classname="">
                <h3 className="text-black-alt">Organzier details</h3>

                
            </div>

            {/* Seller's Details Second Section */}
            <div className=' mt-4'>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-14 font-inter mb-4">
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Registered Business</p>
                        <p className='text-sm payments-color break-words'>Registration Type</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>{businessDetails?.business_name || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Business Name</p>
                    </div>

                    <div>
                        <p className='text-sm payments-title-color font-semibold'>{businessDetails?.id || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Registration Number</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>{businessDetails?.phone_number || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Phone</p>
                    </div>
                
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>{`${businessDetails?.first_name} ${businessDetails?.last_name}` || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Representative name</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>{businessDetails?.email_address || businessDetails?.business_email_address || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Email address</p>
                    </div>
                
                    <div className="md:col-span-2">
                        <p className='text-sm payments-title-color font-semibold'>{businessDetails?.address || "Not Set"}</p>
                        <p className='text-sm payments-color break-words'>Address</p>
                    </div>
                
                        {/* <div>
                            <p className='text-sm payments-title-color font-semibold'>Email</p>
                            <p className='text-sm payments-color break-words'>allenarab@paysocialpaysocial.com</p>
                        </div>
                        <div>
                            <p className='text-sm payments-title-color font-semibold'>Username</p>
                            <p className='text-sm payments-color break-words '>@allenaraba</p>
                        </div> */}
                </div>
                
                { businessDetails && businessDetails.status === "pending" &&
                    (
                        <div className=' mt-6 flex justify-end items-center gap-4'>
                            <button onClick={() => approveSubmit()} 
                            className="bg-primary-green font-light text-white px-12 py-3 w-fit rounded-lg">
                                Approve
                            </button>
                            <button onClick={() => handleNotification("disapprove")} 
                            className="bg-primary-red font-light text-white px-12 py-3 w-fit rounded-lg">
                                Disapprove
                            </button>
                            
                        </div>
                    )
                }

                { businessDetails && businessDetails?.status === "active" &&
                    (
                    <div className=' mt-6 flex justify-end items-center gap-4'>
                        
                        <button onClick={() => handleNotification("disapprove")} 
                        className="bg-primary-red font-light text-white px-12 py-3 w-fit rounded-lg">
                            Suspend
                        </button>
                    
                    </div>
                )}

                { businessDetails && businessDetails?.status === "suspended" &&
                (
                    <div className=' mt-6 flex justify-end items-center gap-4'>
                        <button onClick={() => unsuspendSubmit()} 
                        className="bg-primary-green font-light text-white px-12 py-3 w-fit rounded-lg">
                            Unsuspend
                        </button>
                        
                        
                    </div>
                )}
                {isLoading && <Loader/>}
            </div>
        </CardHoc>
        
    </HOC>
    </>
  )
}

export default BusinessDetails