import React from 'react'
import {BsThreeDotsVertical} from 'react-icons/bs'

import "./Card.css"
import Barchart from '../charts/Barchart';
import AreaChart from '../charts/AreaChart';

function ChartCard(props) {
  return (
    <div data-aos="fade-up" className="p-4 px-6 card-shadow w-full">
            <div className="flex justify-between items-center flex-wrap gap-4">
                <p className="text-black-alt capitalize">{props.title}</p>
                <div className={`flex justify-center items-center text-lg rounded-full text-black-alt`}>
                    <BsThreeDotsVertical/>
                </div>
            </div>

            {props.barChart && <Barchart data={props.data}/>}
            {props.areaChart && <AreaChart data={props.data}/>}
        </div>
  )
}

export default ChartCard