export const images = {
    logo : [require("./logo.png"), "paysocial logo"],
    leftLogo : [require("./left-logo.png"), "paysocial logo"],
    whiteLogo : [require("./Tickit-white.png"), "tickit logo"],
    paysocialLogo : [require("./paysocial-logo.png"), "paysocial logo"],
    iphoneImages : [require("./iphones-images.png"), "vivacity dashboard"],
    iphoneBottom : [require("./iphone-bottom.png"), "iphones bottom"],
    notificationQueue : [require("./Notification-queue.png"), "Notification Queue"],
    appStore : [require("./mobile-log.png"), "app store"],
    playStore : [require("./google-play-logo.png"), "Google Play Store"],
    appStoreWhite : [require("./mobile-logo-white.png"), "app store"],
    playStoreWhite : [require("./google-play-logo-white.png"), "Google Play Store"],
    ticket : [require("./ticket.png"), "ticket"],
    ticketYellow : [require("./ticket-yellow.png"), "ticket yellow"],
    barcode : [require("./barcode.png"), "barcode"],
    device : [require("./device.png"), "device"],
    favourite : [require("./favourite.png"), "favourite"],
    share : [require("./share.png"), "share"],
    passwordIcon : [require("./password-icon.png"), "create password"],
    loading: [require("./loading.png"), "loading"],
    emailIcon : [require("./email-icon.png"), "create email"],
    checkIcon : [require("./check-icon.png"), "check pass"],
    notificationActive : [require("./notification-active.png"), "notification is active"],
    arrowDown: [require("./arrow-down.png"), "arrow down"],
    filterLines: [require("./Filters-lines.png"), "filter lines"],
    tagIcon: [require("./tag-icon.png"), "Tag"],
    eventImage: [require("./event-image.png"), "Event"],
    calendarIcon: [require("./calendar.png"), "Organizer calendar"],
    mapPin: [require("./map-pin.png"), "Organizer location"],
    ticketSuccess: [require("./ticket-success.png"), "ticket success pay"],
    qrBarCode: [require("./qr-code-image.png"), "QR Bar Code"],
    ticketImage: [require("./ticket-image.png"), "Ticket"],
    profileImage: [require("./profile-img.png"), "Profile"],
    successIcon: [require("./success-icon.png"), "success"],
    dropImageIcon: [require("./drop-image-icon.png"), "drop zone"],
    UploadCloud: [require("./upload-cloud.png"), "drop zone"],
    ticketCode: [require("./ticket-code.png"), "ticket code"],
    

    overviewIcon: [require("./grid-icon.svg"), "overview grid"],
    paymentsIcon: [require("./credit-card-icon.svg"), "payments"],
    customersIcon: [require("./users-icon.svg"), "customers"],
    staffsIcon: [require("./staffs-icon.svg"), "staffs"],
    disputeIcon: [require("./mail-icon.svg"), "dispute"],
    settingsIcon: [require("./settings-icon.svg"), "settings"],
    successModalIcon: [require("./success-modal-icon.png"), "success"],
    customerPicture: [require("./customer-pic.png"), "customer"],
    warningIcon: [require("./warning-icon.png"), "warning"],
    entypoTicketIcon:[require('./entypo_ticket.png'),
    'entypo ticket']
}