import React, {useMemo, useEffect, useState} from 'react'

// import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'

// DATA
import {USER_COLUMNS} from '../../util/columns';


//custom components
import Loader from '../../../../UI_Elements/loader/Loader';
import TablePagination from '../TablePagination/TablePagination';

// custom icons


// custom css
import './tables.css'
import { useApi } from '../../api/api-hook';
import { userEndpoints } from '../../api';





function UserTable({searchTerm, refresh}) {    
    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : 1,
        "page_number":15,
        "status":"0",
        "search_text":searchTerm
    })

    const { getData, error, isLoading} = useApi()

    useEffect(() => {
        setTableData(null)

        const fetchData = async() => {
            
            try {
                const responseData = await getData(
                    userEndpoints['LIST-USERS-PAGINATED'], 
                    null, 
                    `?page_number=${detailsData.current_page}&page_size=${detailsData.page_number}`)


                setTableData(responseData.data);

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data.users){
                        setCellData((cell)=>[...cell, {
                            "userId":file?.id || "",
                            "name": `${file?.first_name} ${file?.last_name}` || "",
                            "email": file?.email_address || "",
                            "phone": file?.phone_number || "",
                            "gender": file?.gender || "",
                            "address": file?.address || "",
                            "city": file?.city || "",
                            "verifiedEmail": file.verified_email ? "True" : "False",
                            "verifiedPhone": file.verified_email ? "True" : "False",
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [getData, error, detailsData, refresh, searchTerm]);


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => USER_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>

        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__border mt-8 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} 
                                    className={`text-xs md:text-sm text-primary-black-70 uppercase 
                                    whitespace-nowrap p-4 px-6`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} key={index} 
                            className={`${index%2 === 0 && "bg-odd-row"} font-sora`}>
                                
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} 
                                        className="px-6 align-middle  text-sm whitespace-nowrap p-4 text-primary-grey">{cell.render('Cell')}</td>
                                    })}
                            
                            </tr>
                        )
                    })}
                    
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {detailsData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                // pages = {pages}
                per_page = {detailsData.page_number}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default UserTable