import React from 'react'
import { useState } from 'react'
import { useSearchParams} from 'react-router-dom'

// custom components
import OtpInput from 'react-otp-input';
import AuthLoading from './components/AuthLoading';
import AuthVerified from './components/AuthVerified';

// custom util function
import { images } from '../assets'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { toast } from 'react-toastify';

// custom hooks
import { useHttpClient } from '../hooks/http-hook';

//css scripts
import 'react-phone-input-2/lib/style.css'
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';
// import {MdOutlineArrowBack} from 'react-icons/md'



function Verification() {
    // const [loading, setLoading ] = useState(false)
    // const [verified, setVerified ] = useState(false)
    const auth = useContext(AuthContext)
    const [verified, setVerified] = useState(false)
    const [searchParams] = useSearchParams();
    const [otp, setOtp] = useState(null)

    const {isLoading, error, sendRequest} = useHttpClient();
    

    const authSubmitHandler = async(e) =>{
        e.preventDefault();
        try{
            const data = JSON.stringify({
                user_id: searchParams.get("userId"),
                method: "email",
                otp : otp
            })
            const responseData = await
                sendRequest(`${process.env.REACT_APP_API_CALL}/user/verification-complete`, 'POST', data, {Authorization: 'Bearer '+auth.token});
                
            if(responseData){
                setVerified(!verified)   
            }
        }
        catch(err){
            console.log(error)
        }
    }


    const initiateVerifyHandler = async(e) =>{
        e.preventDefault();
        try{
            const data = JSON.stringify({
                user_id: searchParams.get("userId"),
                method: "email"
            })

            const responseData = await
                sendRequest(`${process.env.REACT_APP_API_CALL}/user/verification-initiate`, 'POST', data, {Authorization: 'Bearer '+auth.token});  
            if(responseData){
                console.log(responseData)
                toast.success("OTP pin resent, check mail", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    icon: ({theme, type}) =>  <img src={images.successIcon[0]} alt={images.successIcon[1]}/>
                });
            }
        }
        catch(err){
            console.log(error)
        }
    }



    return (
        <>  
        
        <AuthLoading loading={isLoading} />

        <AuthVerified verified={verified} token={searchParams.get("token")}/>
        <div className='auth_bg flex justify-center mt-16 '>
            <div className='mx-auto' data-aos="fade-left">
                <div className='text-center'>
                    <img src={images.emailIcon[0]} alt={images.emailIcon[1]} className="mx-auto mb-4"/>
                    <h3 className='font-semibold text-xl md:text-2xl text-primary-black mb-2'>Check your email</h3>
                    <p className='text-primary-black-70 mx-auto text-center text-sm md:text-base'>
                        We sent a verification code to <br/>
                        <span className='font-semibold'>{searchParams.get("email")}</span>
                    </p>
                    
                </div>

                <form className='my-4 px-4 md:px-0 md:w-96 ' onSubmit={authSubmitHandler}>
                    
                <div className='w-full flex__center my-4'>
                    <OtpInput
                        value={otp}
                        onChange={(otp) => setOtp(otp)}
                        numInputs={6}
                        containerStyle={`w-full otp-container flex justify-center items-center flex-wrap md:flex-nowrap gap-2`}
                        inputStyle={`otp-input text-primary font-medium`}
                        isInputNum	
                        shouldAutoFocus={true}
                        separator={<span></span>}
                    />
                </div>


                    {/* <input
                        type="submit"
                        disabled={otp !== null ? false:true}
                        className={`w-full ${otp !== null ? "bg-primary cursor-pointer":"bg-primary-gray cursor-not-allowed" } text-white py-3 rounded-xl mt-6`}
                        value="Verify email"/> */}

                    <button type="submit" disabled={otp !== null ? false:true}
                    className={`flex items-center justify-center w-full ${otp !== null ? "bg-primary cursor-pointer":"bg-primary-gray cursor-not-allowed" }
                    cursor-pointer bg-primary text-white py-3 rounded-xl mt-6`}>
                        Verify email {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                    </button>
                    
                    <p className=' text-sm text-center mt-4 text-primary-black-70'>Didn’t receive the email?
                        <button 
                        onClick={initiateVerifyHandler} 
                        className='text-primary-green-dark font-semibold ml-1'> Click to resend</button>
                    </p>

                    {/* <Link to={`/createpassword`} className='flex__center mt-4 text-primary-black-70'>
                        <MdOutlineArrowBack />
                        <p >Back</p>
                    </Link> */}
                </form>

                
            </div>
        </div>
        </>
    )
}

export default Verification