import React, { useState } from 'react'
import NewPasswordComp from './components/NewPasswordComp'
import ResetPasswordConfirm from './components/ResetPasswordConfirm'


function NewPassword() {
    const [reset, setReset] = useState(true)
   
    const resetHandler = () => setReset(!reset)
    return (
        <div className='auth_bg flex justify-center mt-16 '>
            {reset ? <NewPasswordComp resetHandler={resetHandler}/> : <ResetPasswordConfirm/>}
        </div>
    )
}

export default NewPassword