import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

//custom components
import CardHoc from '../../components/card/CardHoc'

//modals


//custom icons
import {BsCalendar4} from 'react-icons/bs'
// import { images } from '../../../../assets'

//custom packages
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import NaijaStates from 'naija-state-local-government';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//custom css
import './events.css'
import 'react-datepicker/dist/react-datepicker.css';
import ImageUploader from './components/ImageUploader'
import Button from '../../components/button/Button'
import AddTicketModal from '../../components/Modal/AddTicket'
import Ticket from './components/Ticket';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment/moment';
import { useContext } from 'react';
import { DataContext } from '../../../../context/DataContext';
import { useApi } from '../../api/api-hook';
import { eventEndpoints } from '../../api';





function Index() {

    const history = useNavigate()
    const [showAddTicket, setShowAddTicket] = useState(false)
    const [type, setType] = useState("event")

    const {id} = useParams()

    const [tickets, setTickets] = useState([])

    const {isLoading:statusLoading, error, postData, getData } = useApi()

    // const profileData = JSON.parse(localStorage.getItem('profileData'));

    const profileData = useContext(DataContext)?.userData

    const handleType = (type) =>{
        return setType(type)
    }

  const initialValues = {
    eventName: '',
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    address:'',
    state:'',
    description: '',
    images: [],
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required('Event Name is required'),
    // startDate: Yup.date().required('Start date is required'),
    // endDate: Yup.date().required('End date is required'),
    // startTime: Yup.string().required('Start time is required'),
    // endTime: Yup.string().required('End time is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    description: Yup.string().required('Description is required'),
    // images: Yup.array().min(1, 'At least one image is required'),
  });


  useEffect(() => {
    const fetchData = async () => {
        try{
            const response = await getData(eventEndpoints['EVENT-DETAILS'], null,`?event_id=${id}`)

            if(response){
                
                let eventDetails = response.data.event
               

                let initialDetails = {
                    eventName: eventDetails?.name || '',
                    startDate: new Date(eventDetails?.start_time) || null,
                    endDate: new Date(eventDetails?.end_time) || null,
                    startTime: eventDetails?.start_time || null,
                    endTime: eventDetails?.end_time || null,
                    address: eventDetails?.address || "",
                    state: eventDetails?.state || '',
                    description: eventDetails?.description || '',
                    images: [eventDetails?.image] || [],
                }
                
                
                setFormValues(initialDetails)

                setTickets(prevData => {
                    const originalArray = {...prevData}

                    return {
                        ...originalArray,
                        ...eventDetails?.availability
                    }
                })

                }
            }
            catch(err){
                console.log(error)
            }
        }   

        if(id){
            fetchData()
        }
    },[getData, error, id])
  

    const setFormValues = (values) => {
        // Update initial values using Formik's setValues function
        formikRef.current.setValues(values);
    };
    const formikRef = React.useRef(null);
  
  const onSubmit = (values) => {

    // Parse Date and Time
    const startdate = moment(values.startDate, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    const starttime = moment(values.startTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    // Combine Date and Time
    const combinedStartTime = moment({
        year: startdate.year(),
        month: startdate.month(),
        date: startdate.date(),
        hours: starttime.hours(),
        minutes: starttime.minutes(),
        seconds: starttime.seconds()
    });

    // Parse Date and Time
    const enddate = moment(values.endDate, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    const endtime = moment(values.endTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    // Combine Date and Time
    const combinedEndTime = moment({
        year: enddate.year(),
        month: enddate.month(),
        date: enddate.date(),
        hours: endtime.hours(),
        minutes: endtime.minutes(),
        seconds: endtime.seconds()
    });

    // Add your logic to submit the form data here
    const formData = new FormData()

    formData.append("organiser", profileData?.id)
    formData.append("name", values.eventName)
    if(type === 'event'){
        formData.append("start_time", moment(`${combinedStartTime}`, "ddd MMM DD YYYY HH:mm:ss [GMT]Z (ZZ)").valueOf())
        formData.append("end_time", moment(`${combinedEndTime}`, "ddd MMM DD YYYY HH:mm:ss [GMT]Z (ZZ)").valueOf())
    }
    formData.append("address", values.address)
    formData.append("state", values.state)
    formData.append("description", values.description)
    // formData.append("images", values.images)
    formData.append("availability", JSON.stringify(tickets))
    formData.append("event_type", type)

    let url = ''

    if(id){
        url = 'UPDATE-EVENT'
    } else{
        url = 'CREATE-EVENT'
    }

    postData(eventEndpoints[url],formData,null).then((res) => {

        const imageData = new FormData()
        imageData.append("event_id", res.data.event.id)
        imageData.append("organiser_id", profileData?.id)
        imageData.append("event_type", type)
        imageData.append("image", values.images[0])
        
  

        postData(eventEndpoints['UPLOAD-EVENT-IMAGE'],imageData,null).then(res => {
            history('/dashboard/events')
        }).catch((error) => {
            console.log(error)
        })
        

    }).catch((error) => {
        console.log(error)
    })
    
   
  };

  const addTicketHandler = (values) =>{
    
    setTickets((prevData) => {
        // Create a new object for the updated state
        const updatedData = { ...prevData };
    
        // Create the new ticket entry
        updatedData[values.ticketName] = {
          quantity: values.quantity || 0,
          price: values.price || 0,
        };
  
        return updatedData;
      });
      
    setShowAddTicket(!showAddTicket)
  }
  
  const onCancelTicket = () => {
    setShowAddTicket(!showAddTicket)
  }

  const deleteTicket = (id) => {
    setTickets((prevData ) => {
        const updatedData = { ...prevData };

        // updatedData.filter(item => item.name === id)
        delete updatedData[id]

        return updatedData
    })
  }
  
  return (
    <>
    <AddTicketModal show={showAddTicket} onCancel={onCancelTicket} onSubmit={addTicketHandler}/>
    <div className='w-full px-10 py-10'>

        <div className="flex items-center mb-2 w-full whitespace-nowrap overflow-x-scroll md:overflow-auto">
            <button onClick={() => handleType("event")} className={`text-primary-grey mr-6 pb-1 ${type === "event" && "border-b border-primary font-medium text-primary"}`}>Create Event</button>
            <button onClick={() => handleType("location")} className={`text-primary-grey mr-6 pb-1 ${type === "location" && "border-b border-primary font-medium text-primary"}`}>Create Location</button>
        </div>  

        <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formikRef} 
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                    <CardHoc className="w-full xl:w-5/6 bg-white">
                        <h1 className="text-sm md:text-base text-primary-black font-bold">Add event info</h1>

                        <div className='w-full flex flex-col mt-3'>
                            <label htmlFor="eventName" className="text-sm font-medium text-primary-black mb-1">Event Name</label>
                            <Field type="text" id="eventName" name="eventName" 
                            className={`event-input`} placeholder="Enter the name of your event"/>
                            <ErrorMessage name="eventName" component="div" />
                        </div>

                        {type === "event" && 
                        <div className="grid grid-cols-2 lg:grid-cols-4 my-6 gap-6">

                        <div className='w-full flex flex-col '>
                            <label className="text-sm font-medium text-primary-black mb-1">Start Date:</label>
                            <div className={`event-input text-black flex justify-end items-center w-full`}>
                                <DatePicker
                                selected={values.startDate}
                                onChange={(date) => setFieldValue('startDate', date)}
                                placeholderText="Choose date"
                                className="!w-full focus:outline-none italic"
                                />
                                <BsCalendar4/>
                            </div>
                            <ErrorMessage name="startDate" component="div" />
                        </div>

                        <div className='w-full flex flex-col '>
                            <label className="text-sm font-medium text-primary-black mb-1">End Date:</label>
                            <div className={`event-input text-black flex justify-end items-center w-full`}>
                                <DatePicker
                                selected={values.endDate}
                                onChange={(date) => setFieldValue('endDate', date)}
                                placeholderText="Choose date"
                                className="!w-full focus:outline-none italic"
                                />
                                <BsCalendar4/>
                            </div>
                            <ErrorMessage name="endDate" component="div" />
                        </div>

                        <div className='w-full flex flex-col '>
                            <label className="text-sm font-medium text-primary-black mb-1">Start Time:</label>
                            <div className={`event-input text-black flex justify-end items-center w-full`}>
                                <DatePicker
                                selected={values.startTime}
                                onChange={(date) => setFieldValue('startTime', date)}
                                placeholderText="06.30PM"
                                className="!w-full focus:outline-none italic"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                />
                            </div>
                            <ErrorMessage name="startTime" component="div" />
                        </div>

                        <div className='w-full flex flex-col '>
                            <label className="text-sm font-medium text-primary-black mb-1">End Time:</label>
                            <div className={`event-input text-black flex justify-end items-center w-full`}>
                                <DatePicker
                                selected={values.endTime}
                                onChange={(date) => setFieldValue('endTime', date)}
                                placeholderText="06.30PM"
                                className="!w-full focus:outline-none italic"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                />
                            </div>
                            <ErrorMessage name="endTime" component="div" />
                        </div>

                    </div>
                    }

                        <div className="grid grid-cols-1 md:grid-cols-2 my-6 gap-6">
                            <div className='w-full flex flex-col'>
                                <label htmlFor="address" className="text-sm font-medium text-primary-black mb-1">Venue Address</label>
                                <Field type="text" id="address" name="address" 
                                className={`event-input italic`} placeholder="e,g Blue star"/>
                                <ErrorMessage name="address" component="div" />
                            </div>

                            <div className='w-full flex flex-col'>
                                <label htmlFor="state" className="text-sm font-medium text-primary-black mb-1">Venue State</label>
                                <Field as="select" id="state" name="state" 
                                className={`event-input italic`}>
                                    <option value="">Select options</option>
                                    {NaijaStates.states().map((item, index) => {
                                        return (
                                            <option key={index} value={item.toString()}>{item}</option>
                                        )
                                    })}
                                
                                
                                </Field>
                                
                                <ErrorMessage name="name" component="div" />
                            </div>
                        </div>

                        <div className='w-full flex flex-col my-4'>
                            <label htmlFor="description" className="text-sm font-medium text-primary-black">Description</label>
                            <ReactQuill 
                                className=" py-4 rounded"
                                value={values.description}
                                onChange={(content) => setFieldValue('description', content)}
                                placeholder="Type event description"
                                modules={{
                                    toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    ['blockquote', 'code-block'],
                                    [{ header: 1 }, { header: 2 }],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    [{ script: 'sub' }, { script: 'super' }],
                                    [{ indent: '-1' }, { indent: '+1' }],
                                    [{ direction: 'rtl' }],
                                    [{ size: ['small', false, 'large', 'huge'] }],
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [{ font: [] }],
                                    [{ color: [] }, { background: [] }],
                                    [{ align: [] }],
                                    ['clean'],
                                    ],
                                }}
                                formats={[
                                    'header',
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strike',
                                    'blockquote',
                                    'list',
                                    'bullet',
                                    'indent',
                                    'link',
                                ]}
                                style={{height:"9rem", borderRadius:"0.25rem"}}
                                />
                        </div>


                        <div className='w-full overflow-x-auto flex flex-col my-8 mt-48 sm:mt-32 md:mt-16 lg:mt-12'>
                            <label htmlFor="images" className="text-sm font-medium text-primary-black mb-3">Choose or upload an image</label>
                            <ImageUploader imageProp={values?.images || []} onUpload={(images) => {
                                
                                setFieldValue("images", [...images]);
                            }}/>
                        </div>


                    </CardHoc>

                    <CardHoc className="w-full xl:w-5/6 bg-white py-4 px-6 my-8">
                        <p className="text-sm font-medium text-primary-black">Create ticket types</p>
                        
                        <Button type="button" onClick={onCancelTicket} textWhite className="my-3 bg-primary-dark">+ Add new ticket</Button>
                       
                        {tickets.length === 0 ? (
                        <div className='addNewTicket p-3 text-primary-black text-sm'>
                            Click the Add new ticket type button above to add a ticket to your event.
                        </div>)
                        :
                        (
                            <div className='flex flex-col gap-3'>
                                {tickets && Object.keys(tickets).map((item, index) => {
                                    return(
                                        <Ticket id={index} name={item} quantity={tickets[item].quantity} 
                                        price={tickets[item].price} deleteTicket={deleteTicket}/>
                                    )
                                })}
                            </div>
                        )}
                        
                    </CardHoc>

                    <div className=' flex justify-end items-center gap-6 w-full xl:w-5/6 mt-8'>
                        <Link to={"/dashboard/events"} className={`flex items-center justify-center w-fit 
                        cursor-pointer bg-white border-border-color shadow text-primary-black py-3 px-12 rounded mt-4`}>
                            Cancel     
                        </Link>
                        <button
                            type='submit'
                            className={`flex items-center justify-center w-fit cursor-pointer bg-primary-dark text-white shadow py-3 px-12 rounded mt-4`}
                        >
                            Save {statusLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters /></span>}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
    </>
  )
}

export default Index