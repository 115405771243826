import React from 'react'
import { useContext } from 'react'
import { DataContext } from '../../../../context/DataContext'
import {AuthContext} from '../../../../context/auth-context';

//custom components
import HOC from '../../components/hoc/Hoc';

//sections
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import Button from '../../components/button/Button'
import ThirdSection from './ThirdSection'
import { useNavigate } from 'react-router-dom';




function Dashboard() {
  const data = useContext(DataContext)
  const auth = useContext(AuthContext);

  const navigate = useNavigate()

  
  return (
    <HOC className='w-full min-h-screen bg-dashboard-bg'>

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col md:flex-row 
        gap-2 md:gap-0 justify-between items-start md:items-center`}>
          <div>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-primary-grey'>
              Welcome back, 
              <span className='capitalize'> 
               {` ${data.userData?.first_name || "John"} ${data.userData?.last_name || "Doe"}`} 
              </span>
            </h1>
            <p className='text-secondary-grey text-sm md:text-base '>Manage your revenue and business here</p>
          </div>

          <Button textWhite 
          onClick={() => {
            auth.logout()
            navigate("/login")
            localStorage.removeItem("profileData")
          }} 
          bgPrimary className="font-sora font-semibold uppercase">
            Logout
          </Button>
        </div> 

        <FirstSection/>

        <SecondSection/>

        <ThirdSection/>
        
    </HOC>
  )
}

export default Dashboard