import React from 'react';
import { Route, Routes } from 'react-router-dom';

//react components
import Dashboard from '../sections/dashboard/Index';
import Sidebar from '../sidebar/Sidebar';
import Users from '../sections/users/Index';
import Staffs from '../sections/staffs/Index';
import Settings from '../sections/settings/Index';
import Transactions from '../sections/transactions/Index';
import TransactionsDetails from '../sections/transactions/TransactionsDetails';
import BusinessDetails from '../sections/business/BusinessDetails';
import Events from '../sections/events/Index';
import CreateEvent from '../sections/events/CreateEvent';
import Business from '../sections/business/Index';

//css scripts
import "../Dashboard.css"




function Index() {

  return (
    <>  
        <Sidebar/>
        <div className='lg:ml-64'>
          <Routes>
            <Route path="overview" exact element={<Dashboard/>}/>
            <Route path="transactions" exact element={<Transactions/>}/>
            <Route path="transactions/:id" exact element={<TransactionsDetails/>}/>
            <Route path="users" exact element={<Users/>}/>
            <Route path="admins" exact element={<Staffs/>}/>
            <Route path="organizers" exact element={<Business/>}/>
            <Route path="organizers/:id" exact element={<BusinessDetails/>}/>
            <Route path="events" exact element={<Events/>}/>
            <Route path="events/create-event" exact element={<CreateEvent/>}/>
            <Route path="events/:id" exact element={<CreateEvent/>}/>
            <Route path="settings" exact element={<Settings/>}/>
            <Route path="*" exact element={<Dashboard/>}/>
          </Routes>
        </div>

    </>
  )
}

export default Index