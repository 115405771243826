import { useHttpClient } from '../../../hooks/less-http-hook'
import { AuthContext } from '../../../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';


export const useProfile = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [userProfile, setUserData] = useState()
    const [profileLoading, setProfileLoading] = useState(isLoading)

    useEffect(() => {
        setProfileLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const profileData = useCallback( async(params) => {
        try {
            // console.log(localStorage.getItem('profileData'))
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}administrator/details${params? params : ""}`, "GET", null, {Authorization: 'Bearer '+auth.token});
            if(responseData){
                
                setUserData(responseData.admin)
            }
            else{
                setUserData(JSON.parse(localStorage.getItem('profileData')))
            }

            return(responseData.admin)
        } catch (err) {
            setUserData(JSON.parse(localStorage.getItem('profileData')))
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const updateProfileData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}administrator/update-details`, "POST", data, {Authorization: 'Bearer '+auth.token});
            
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const updateImageData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}administrator/change-image`, "POST", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    

    return {userProfile, profileLoading, profileData, updateProfileData, updateImageData}
}