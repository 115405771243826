import React from "react";


import "./Card.css"


export function CardHoc(props){

    return (
        <div className={`p-6 px-6 card-shadow w-full ${props.className}`}>
            {props.children}
        </div>
    );
}

export default CardHoc;