import React from 'react'
import { useContext } from 'react'
import { DataContext } from '../../../../context/DataContext'
import { images } from '../../../../assets'
import Input from '../../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../../../../util/validators'

// custom hooks
import {useForm} from '../../../../hooks/form-hook'
import { useState, useEffect } from 'react'
import {useProfile} from '../../api/profile-hook'

//custom components
import PhoneInput from 'react-phone-input-2'
import PhotoUpload from './PhotoUpload'
import Loader from '../../../../UI_Elements/loader/Loader';
import Button from '../../components/button/Button';

function Profile() {
    
    const [openPhotoUpload, setOpenPhotoUpload] = useState(false)
    
    const data = useContext(DataContext).userData
    // const data = JSON.parse(localStorage('profileData'))
    console.log(data)

    const {userProfile,profileLoading, profileData, updateProfileData} = useProfile()

    // useEffect(() => {
    //     profileData().then((res) => {
    //         setPhone(res.phone_number)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // },[profileData])

    const [phone,setPhone] = useState(data?.phone_number || "")

    
    const [isphonevalid, setisphonevalid] = useState(false)

    const [formState,
        inputHandler] = useForm({

        email: {
            value: data?.email_address || "",
            isValid: false
        },
        firstname: {
            value: data?.first_name || "",
            isValid: false
        },
        lastname: {
            value: data?.last_name || "",
            isValid: false
        }
    }, false);

    const openPhotoUploadHandler = () =>{
        setOpenPhotoUpload(!openPhotoUpload)
    }

    const updateProfileSubmit = (event) => {
        event.preventDefault()

        const formData = new FormData()

        formData.append("administrator_id", data?.id)
        formData.append("first_name", formState.inputs.firstname.value)
        formData.append("last_name", formState.inputs.lastname.value)
        formData.append("email_address", formState.inputs.email.value)
        formData.append("phone_number", phone)
        // const permissions = Array.from(data.userData?.adminroles || []);
        // permissions.forEach((permission) => {
        // formData.append("permissions[]", permission);
        // });

        // console.log(data.userData)

        updateProfileData(formData).then((res => {
            
            data.UpdateData(res)
        })).then(() =>{
            profileData()
        }).catch((error) => {
            console.log(error)
        })
        
    }


  return (
    <>
    <PhotoUpload show={openPhotoUpload} onCancel={openPhotoUploadHandler}/>
    <div className='px-2 md:px-4 xl:px-8 py-4'>
        <div>
            <h3>Profile</h3>
            {/* <img 
            src={data?.image} 
            alt={images.profileImage[1]} 
            className="w-16 h-16 rounded-full border border-primary-blue my-2 md:ml-4"/>
            <button onClick={openPhotoUploadHandler} className='text-primary-blue text-sm'>
                Change image
            </button> */}
        </div>
        

        <form onSubmit={(e) => {
            e.preventDefault()
            console.log("submitting")
            updateProfileSubmit(e)
        }}>
        <div className='mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 gap-x-8 items-center place-items-start '>
        
            <Input
                type="text"
                placeholder='Enter your first name'
                label="First Name"
                initialValue={data?.first_name || ""}
                initialValid={true}
                labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                id="firstname"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid first name"
                onInput={inputHandler}
                divClassName={`mb-3 `}
                containerClassName={`relative  `}
                className={`appearance-none ${formState.inputs.firstname.value && "bg-light-purple"}  
                input-form p-3 text-primary-black-70 focus:outline-none 
                focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
    
            <Input
                type="text"
                placeholder='Enter your last name'
                label="Last Name"
                initialValue={data?.last_name || ""}
                initialValid={true}
                labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                id="lastname"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid last name"
                onInput={inputHandler}
                divClassName={`mb-3 `}
                containerClassName={`relative  `}
                className={`appearance-none ${formState.inputs.lastname.value && "bg-light-purple"}  
                input-form p-3 text-primary-black-70 focus:outline-none 
                focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
    
            <Input
                type="email"
                placeholder='Enter your email address'
                label="Email Address"
                initialValue={data?.email_address || ""}
                initialValid={true}
                labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                id="email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email"
                onInput={inputHandler}
                divClassName={`mb-3 `}
                // readOnly
                containerClassName={`relative`}
                className={`appearance-none  ${formState.inputs.email.value && "bg-light-purple"} 
                input-form p-3 text-primary-black-70 focus:outline-none 
                focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
    
            
    
            <div className='w-full mb-auto'>
                <label className='text-sm mb-2 -mt-1 font-semibold'>Phone Number</label>
                
                <PhoneInput 
                    country={'ng'}
                    value={phone || ""}
                    onChange={phone => setPhone(phone)}
                    placeholder="900 009 211"
                    // isValid={(value) => {
                    //     if (value.match(/234/) && value.length >= 13) {
                    //     setisphonevalid(true)
                    //     return true
                    //     } else {
                    //         setisphonevalid(false)
                    //         return false
                    //     }
                    // }}
                    inputProps={{
                    name: 'phone',
                    required: true
                    }}
                    containerClass={`w-full mt-1`}
                    inputClass={`phone_input w-full`}/>
                
                    {/* <div className={`flex items-center mt-1 ${isphonevalid ? "visible" : "invisible"} text-xs`}>
                        <div className='w-3 h-3 p-2 bg-primary-gray flex__center rounded-full text-white'>
                            !
                        </div>
                        <p className='text-primary-gray ml-1'>Please start with +234 & have at least 13 digits</p>
                    </div> */}
                
                
            </div>
    
            </div>
            <div className='w-full mt-6 flex justify-end' >
                <Button type="submit" bgPrimary textWhite shadow  className={`ml-auto `}>
                    {profileLoading ? "Saving...":"Save Changes"}
                </Button>
            </div>
        </form>

        
    </div>

    
    </>
  )
}

export default Profile