import React from 'react'
import { MdOutlineArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { images } from '../../../assets'

function CheckComp() {
  return (
    <div className='mx-auto' data-aos="fade-down">
            <div className='text-center'>
                <img src={images.emailIcon[0]} alt={images.emailIcon[1]} className="mx-auto mb-6"/>
                <h3 className='font-bold text-xl md:text-2xl text-primary-black mb-2'>Check your email</h3>
                <p className='text-primary-gray text-sm md:text-base'>We sent a password reset link to olivia@vivacity.com</p>
            </div>

            <div className='my-8 flex__center'>
                <p className='px-20 py-3  bg-primary focus:outline-primary  rounded-lg mx-auto text-white'>Please open email app</p>
            </div>

            <p className='text-primary-gray text-center mt-3 text-sm md:text-base'>Didn’t receive the email? <button className='text-primary-green font-medium'>Click to resend</button></p>
            <Link to='/login' className='flex__center mt-4 font-semibold cursor-pointer text-primary-black-70'>
                <MdOutlineArrowBack/>
                <p className='text-sm'>Back to log in</p>
            </Link>


            
        </div>
  )
}

export default CheckComp