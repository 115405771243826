import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'

import TransactionsTable from '../../components/tables/TransactionsTable'
import { useApi } from '../../api/api-hook';

function FirstSection() {
    const [searchTerm, setSearchTerm] = useState('');
    const { getData,error} = useApi()

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
        try{

        }
        catch(err){
            console.log(error)
        }
      };
    

    return(
        <>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">


            <p className='text-black-text text-sm font-semibold '>All Transactions</p>     

            <form onSubmit={handleSubmit}>
                <div className="flex items-center pl-2 search-payments bg-white ">
                    <RiSearchLine classname="text-lg  text-primary-grey"/>
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="p-3 rounded-lg outline-none border-none"
                    />
                </div>
                
            </form>

        </div>

        <TransactionsTable searchTerm={searchTerm}/>

        </>
    )
}

export default FirstSection