import React from 'react'

import Chart from "react-apexcharts";
import { abvNum } from "../../util/functions";

import './chart.css'

function Barchart({data}) {
    const series = {
        name: "BarChart",
        data: Object.values(data)
      }

    
    
      let options = {
        colors: ["#CD0053"],
        
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            rangeBarOverlap: true,
            columnWidth: '40%',
            borderRadius:5
          }
        },
        dataLabels: {
          enabled: false,
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val: any): string {
        //       return metricValueFormatter(val, 4)
        //     }
        //   }
        // },
        xaxis: {
          categories: Object.keys(data),
          axisTicks: {
            show: false,
          },
          title: {
            text: 'Months'
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return abvNum(val);
            }
          }
        },
        
        annotations: {
          points: [
            
          ]
        }
      }

  return (
    <div>
        <Chart
            type="bar"
            options={options}
            series={[series]}
            width={"100%"}
        />
  </div>
  )
}

export default Barchart