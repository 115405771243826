import React, { useContext } from 'react'
import Input from '../../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../../../../util/validators'

// custom hooks
import {useForm} from '../../../../hooks/form-hook'
import { usePassword } from '../../api/password-hook';

//custom components
import Button from '../../components/button/Button';
import { toast } from 'react-toastify';
// import Loader from '../../../../UI_Elements/loader/Loader';
// import { DataContext } from '../../../../context/DataContext';

function Password() {
   
    const {profileLoading, updatePasswordData} = usePassword()

    const data = JSON.parse(localStorage.getItem("profileData"))

    const [formState,
        inputHandler] = useForm({

        oldPassword: {
            value: '',
            isValid: false
        },
        newPassword: {
            value: '',
            isValid: false
        },
        confirmPassword: {
            value: '',
            isValid: false
        }
    }, false);
    

    const updatePasswordSubmit = (event) =>{
        event.preventDefault()

        if(formState.inputs.newPassword.value !== formState.inputs.confirmPassword.value){
            
            return toast.error("Passwords do not match", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
        }

        try{
            const formData = new FormData()

            formData.append("administrator_id", data?.id)
            formData.append("current_password", formState.inputs.oldPassword.value)
            formData.append("new_password", formState.inputs.newPassword.value)
            formData.append("new_password_confirmation", formState.inputs.confirmPassword.value)
            
            updatePasswordData(formData)
        }
        catch (err) {
            toast.error("server is currently down", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            console.log(err)
        }
    }

    


  return (
    <>
    <form 
        onSubmit={e => updatePasswordSubmit(e)} >
    <div className='px-2 md:px-4 xl:px-8  py-4'>

        <div
        className='mt-2 grid grid-cols-1 gap-4 gap-x-8 '>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 gap-x-8 '>
                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="Old Password"
                    labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                    id="oldPassword"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)]}
                    errorText="Please enter a valid password not leass than 8 chars"
                    onInput={inputHandler}
                    divClassName={`mb-2 `}
                    containerClassName={`relative  `}
                    className={`appearance-none  
                    input-form p-3 w-full text-primary-black focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>

                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="New Password"
                    labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                    id="newPassword"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)]}
                    errorText="Please enter a valid password not leass than 8 chars"
                    onInput={inputHandler}
                    divClassName={`mb-2 `}
                    containerClassName={`relative  `}
                    className={`appearance-none  
                    input-form p-3 w-full text-primary-black focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
            </div>
            <div  className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 gap-x-8 '>
                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="Confirm Password"
                    labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                    id="confirmPassword"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)]}
                    errorText="Please enter a valid password not leass than 8 chars"
                    onInput={inputHandler}
                    divClassName={`mb-2 `}
                    containerClassName={`relative  `}
                    className={`appearance-none  
                    input-form p-3 w-full text-primary-black focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
            </div>

        </div>
    </div>

    <div className='w-full mt-6 flex justify-end' >
        <Button 
        type="submit" 
        bgPrimary 
        textWhite shadow className={`ml-auto `}>
            {profileLoading ? 'Saving...' : "Save Changes"}
        </Button>
    </div>
    </form>
    </>
  )
}

export default Password