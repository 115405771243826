import React, {useMemo, useEffect, useState, useContext} from 'react'

// import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../../context/auth-context';


// import moment from 'moment';


// DATA
import {STAFF_COLUMNS} from '../../util/columns';


//custom components
import Loader from '../../../../UI_Elements/loader/Loader';
import TablePagination from '../TablePagination/TablePagination';
import PillTab from '../pills/PillTab';


// custom icons


// custom css
import './tables.css'
import WarningModal from '../Modal/WarningModal';
import { useStaff } from '../../api/staff-hook';
import { useApi } from '../../api/api-hook';
import { administratorEndpoints } from '../../api';





function StaffTable(props) {    

    const auth = useContext(AuthContext)

    const {updateStatus} = useStaff()
    // const history = useNavigate()

    const [eventModal, setEventModal] = useState(false)

    const [status, setStatus] = useState("");
    const [rowId, setRowId] = useState("");

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : 1,
        "page_number":15,
        "status":"0",
        "search_text":props.searchTerm
    })

    const [pages, setPages] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const {postData, getData, error, isLoading} = useApi()

    useEffect(() => {
        setTableData(null)

        const fetchData = async() => {
            
            try {
                const responseData = await getData(
                    administratorEndpoints['LIST-ADMINISTRATOR-PAGINATED'], 
                    null, 
                    `?page_number=${detailsData.current_page}&page_size=${detailsData.page_number}`)


                setTableData(responseData.data);
                setPages(responseData.data.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data.administrators){
                        setCellData((cell)=>[...cell, {
                            "userId":file?.id || "",
                            "name": `${file?.first_name} ${file?.last_name}` || "",
                            "email": file?.email_address || "",
                            "phone": file?.phone_number || "",
                            "roleAccess": "Admin" || "",
                            "status": file?.status || ""
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [getData, error, detailsData, refresh, props.refresh]);





    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const eventHandler = () => {
        setEventModal(!eventModal)
    }
    // const onCancelEventHandler = () => {
    //     setEventModal(false)
    // }


    const statusChangeHandler = (statusdata, rowdata) =>{
        
        setStatus(statusdata)
        setRowId(rowdata)

        eventHandler()
    }
    

    const statusSubmitHandler = () =>{
        const formData = new FormData()

        formData.append("status", status)
        formData.append("administrator_id", rowId)

        let url = administratorEndpoints['UNSUSPEND-ADMINISTRATOR']
        if(status === "active"){
            url = administratorEndpoints['SUSPEND-ADMINISTRATOR']
        }

        postData(url, formData, `?administrator_id=${rowId}`).then(() => {
            eventHandler()
            setRefresh(!refresh)
        }).catch((error) =>console.log(error))
    }



    const columns = useMemo(() => STAFF_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>

        <WarningModal 
        show={eventModal} onCancel={eventHandler} onSubmitButton={statusSubmitHandler}
        title={status === "active" ? "Suspend user" : "Activate user"} 
        text={`Are you sure you want to ${status === "active" ? "suspend" : "activate"}  admin ?`}
        CancelText="Cancel" 
        SubmitText="Yes" 
        textClassName="text-sm"
        cancelClassName="w-full"
        submitClassName="w-full"/>

        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__border mt-8 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} 
                                    className={`text-xs md:text-sm text-primary-black-70 uppercase 
                                    whitespace-nowrap p-4 px-6`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} key={index} 
                            className={`${index%2 === 0 && "bg-odd-row"} font-sora`}>
                                
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} 
                                        className="px-6 align-middle  text-sm whitespace-nowrap p-4 text-primary-grey">{cell.render('Cell')}</td>
                                    })}
                                
                                <td className='px-6 align-middle  text-sm whitespace-nowrap p-4'>
                                    {/* <button className={`${row.original.status === '0' ? 'text-primary-purple' : ' text-gray-600'}`} 
                                    onClick={() => updateStatus(row.original.id)} disabled={row.original.status === '0' ? false : true}>
                                        {`${row.original.status === '0' ? 'Claim' : 'Claiming'}`} 
                                    </button> */}
                                    <PillTab 
                                    color={`${row.original.status === "active" ? "red" : "green"}`}   
                                    text={`${row.original.status === "active" ? "Suspend" : "Unsuspend"}`} 
                                    onClick={() => statusChangeHandler(row.original.status, row.original.userId) }/>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {detailsData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                // pages = {pages}
                per_page = {detailsData.page_number}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default StaffTable