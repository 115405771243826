import React, { useEffect, useState } from 'react'

//custom components
import Card from '../../components/card/Card'
import { useApi } from '../../api/api-hook';
import { businessEndpoints, transactionEndpoints, administratorEndpoints } from '../../api';


function FirstSection() {
  const [adminDetails, setAdminDetails] = useState({
    revenue:0,
    businesses:0,
    admins:0
  })

  const {getData, error} = useApi();

  useEffect(()=>{
    const revenueHandler = async() => {
      try {

          const [revenueData, businessData, adminData] = await Promise.all([
            getData(transactionEndpoints['MONTHLY-REVENUE']),
            getData(businessEndpoints['COUNT-ORGANISERS']),
            getData(administratorEndpoints['COUNT-ADMINISTRATORS'])

          ])
          

          if(revenueData){
            let month_data_obj = revenueData.data.monthly_revenue
            
            const month_data_sum = Object.values(month_data_obj).reduce((acc, value) => {
                // Check if the value is numerical
                if (!isNaN(value)) {
                  // Add the numerical value to the accumulator
                  return acc + value;
                }
                // If the value is not numerical, return the accumulator unchanged
                return acc;
              }, 0);
              setAdminDetails((prevData) => {
                return {
                  ...prevData,
                  revenue:month_data_sum
                }
              })
          }

          if(businessData){
            setAdminDetails((prevData) => {
              return {
                ...prevData,
                businesses:businessData?.data?.number_of_organisers
              }
            })
          }

          if(adminData){
            setAdminDetails((prevData) => {
              return {
                ...prevData,
                admins:adminData?.data?.number_of_administrators
              }
            })
          }
          

      } catch (err) {
          
          console.log(error)
      }

    };
    revenueHandler()
  },[getData, error])
  return (
    <div className='mt-6 grid 
        grid-cols-1 place-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6'>
            <Card 
              title="Revenue"
              amount={`${adminDetails.revenue}`} currency/>
              
            <Card 
              title="Businesses"
              amount={`${adminDetails.businesses}`}
            />

            <Card 
              title="Admins"
              amount={`${adminDetails.admins}`}
            />

        </div>
  )
}

export default FirstSection