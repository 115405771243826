import React, { useContext } from 'react'

// custom components
import Input from '../UI_Elements/Input/Input'

//custom icons
import {AiOutlineLoading3Quarters} from 'react-icons/ai'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../util/validators'

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';

import { Link, useNavigate } from 'react-router-dom'
import { TickitIcon } from '../components/SvgIcons';

function Login() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate()

    const {isLoading, sendRequest, error} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const formData = new FormData()

            formData.append('email_address', formState.inputs.email.value)
            formData.append('password', formState.inputs.password.value)

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}administrator/signin`, 'POST', formData, {});

            if(responseData){
              
                auth.login(responseData.data.token);
                localStorage.setItem("profileData", JSON.stringify(responseData.data.administrator))
                navigate("/dashboard/overview")
            }
            

        } catch (err) {
            
            console.log(error)
        }

        
        
    };

  return (
    <div className='auth_bg flex flex-col items-center'>
         {/* data-aos="fade-up" */}
        <div data-aos="fade-up" className='mx-auto bg-white p-8 pb-4 mt-16 lg:mt-24 h-fit auth-box'>
            <div className='text-center'>
                <Link to="/" className='font-andarilho font-medium flex justify-center text-primary text-4xl mb-4'>
                    <TickitIcon/>
                    {/* <p>Paysocial</p> */}
                </Link>
                <h3 className='font-semibold text-lg md:text-xl text-primary-black mb-2'>Login Account</h3>
            </div>

            <form className='my-4 px-4 md:px-0 md:w-96' onSubmit={authSubmitHandler}>
                <Input
                    type="email"
                    placeholder='Enter your email'
                    label="Email*"
                    labelClassName={`text-xs mb-2 font-semibold form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    divClassName={`mb-3 `}
                    containerClassName={`relative  `}
                    className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"}  
                    input-form p-3 text-primary-black focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>

                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="Password*"
                    labelClassName={`text-xs mb-2 font-semibold form_login_label`}
                    id="password"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid password"
                    onInput={inputHandler}
                    divClassName={`mb-3 `}
                    containerClassName={`relative  `}
                    className={`appearance-none ${formState.inputs.password.value && "bg-light-purple"}  
                    input-form p-3 w-full text-primary-black focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
            

                <button type="submit" className={`flex items-center justify-center w-full 
                cursor-pointer bg-primary-dark text-white py-3 rounded-xl mt-4`}>
                    Sign In {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                </button>
                <div className='mt-6 flex__center'>
                    <Link to="/forgotpassword" className=' !text-primary text-sm text-center font-medium'>I forgot my password</Link>
                </div>
            </form>

            
        </div>
        <p className='mt-8 lg:mt-12 text-center'>© Copyright 2023 Tickit Limited</p>
    </div>
  )
}

export default Login