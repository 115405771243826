import { useHttpClient } from '../../../hooks/http-hook'
import { AuthContext } from '../../../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';


export const usePassword = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [profileLoading, setProfileLoading] = useState(isLoading)

    useEffect(() => {
        setProfileLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const updatePasswordData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}administrator/update-password`, "PATCH", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    

    return {profileLoading, updatePasswordData}
}