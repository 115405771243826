import React from "react";
import {BsThreeDotsVertical} from 'react-icons/bs'

import { number_format } from "../../util/functions";

import "./Card.css"


export function Card(props){

    return (
        <div data-aos="fade-up" className="p-6 px-6 card-shadow w-full">
            <div className="flex justify-between items-center flex-wrap gap-4">
                <p className="text-primary-black font-semibold capitalize">{props.title}</p>
                <div className={`flex justify-center items-center text-lg rounded-full text-primary-gray`}>
                    <BsThreeDotsVertical/>
                </div>
            </div>

            <h1 className='text-2xl md:text-3xl lg:text-4xl font-medium text-black-alt mt-4'>{props.currency ? "₦" : ""}{number_format(props.amount)}</h1>
        </div>
    );
}

export default Card;