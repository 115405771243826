import { useHttpClient } from '../../../hooks/less-http-hook'
import { AuthContext } from '../../../context/auth-context'
import { useContext } from 'react'
import { useCallback } from 'react';


export const useApi = () =>{

    const {isLoading, error, sendRequest} = useHttpClient();
    

    const auth = useContext(AuthContext)

    const getData = useCallback( async(url,data,params) => {
        try {
            
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${url}${params?params:""}`, "GET", null, {Authorization: 'Bearer '+auth.token});
            
            return responseData
            

        } catch (err) {
            
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const postData = useCallback( async(url,data,params) => {
        try {
            
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${url}${params?params:""}`, "POST", data, {Authorization: 'Bearer '+auth.token});

            return responseData

        } catch (err) {
            
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const patchData = useCallback( async(url,data,params) => {
        try {
            
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${url}${params?params:""}`, "PATCH", data, {Authorization: 'Bearer '+auth.token});

            return responseData

        } catch (err) {
            
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const deleteData = useCallback( async(url,data,params) => {
        try {
            
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${url}${params?params:""}`, "DELETE", data, {Authorization: 'Bearer '+auth.token});

            return responseData

        } catch (err) {
            
            console.log(error)
        }
    },[auth.token, error, sendRequest])


    

    return { isLoading,error, getData, postData, patchData, deleteData}
}