import { useState, useCallback, useEffect } from 'react';
import { useProfile } from '../pages/dashboard/api/profile-hook';


export const useData = (isToken) => {
  const [userData, setUserData] = useState(null);
  
  // const [userId, setUserId] = useState();
  const {userProfile, profileLoading: dataLoading, profileData} = useProfile()


  const updateData = useCallback((data) => {
    setUserData({
      ...userProfile,
      ...data
    })

  },[userProfile])


  useEffect( () => {

    const fetchData = async (id) => {
      await profileData(`?administrator_id=${id}`)
      .then(res => {
        setUserData(res)
      })
      .catch(err => {
        console.log("got to catch")
        setUserData(JSON.parse(localStorage.getItem('profileData')))
        console.log(err)
      })
    }

    if(localStorage.getItem('profileData') && isToken){
        
        const data = JSON.parse(localStorage.getItem('profileData'))
        
        fetchData(data?.id); 
      }
    
  }, [profileData, isToken]); 

  useEffect(() => {
    
    if(userProfile){
      setUserData(userProfile)
    }
    else{
      setUserData(JSON.parse(localStorage.getItem('profileData')))
    }
  },[userProfile, isToken])

  return { userData, updateData, dataLoading };
};