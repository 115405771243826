import React from 'react'
// import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { abvNum } from "../../util/functions";


function AreaChart({data}) {
    const options = {
        chart: {
          toolbar: {
            show: false
          }
        },
       xaxis: { 
        
        categories:  Object.keys(data) || [],
        title: {
          text: 'Months'
        }
      }, 
      dataLabels: { enabled: false, }, 
      stroke: {
        width: 3
        },
      markers: {
        size: 0,
        offsetY: -5,
        colors: ["#CD0053", "#E2C9D3"],
        strokeColors: "#CD0053",
        strokeOpacity: 0.4,
        strokeWidth: 10,
        hover: {
          size: 5,
        }
      },
      colors: ["#CD0053", "#E2C9D3"],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.6,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [20, 90, 100],
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return abvNum(val);
          }
        },
      },
      grid: {
        // show: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
    
    };

    const series = [ { 
        name: "", 
        data:  Object.values(data) || [] }];


  return (
    <div className=" ">
        <ReactApexChart options={options} series={series} type="area" />
    </div>
  )
}

export default AreaChart