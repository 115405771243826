import React from 'react'

function Pill(props) {
  const {color, className, text} = props

  if(color === "green"){
    return (
      <div className={`text-xs font-semibold px-3 py-2 w-fit rounded-2xl text-center 
      bg-primary-light-green text-primary-green ${className}`}>
          {text}
      </div>
    )
  }
  if(color === "red"){
    return (
      <div className={`text-xs font-semibold px-3 py-2 w-fit rounded-2xl text-center 
      bg-primary-light-red text-primary-red ${className}`}>
          {text}
      </div>
    )
  }
  if(color === "yellow"){
    return (
      <div className={`text-xs font-semibold px-3 py-2 w-fit rounded-2xl text-center 
      bg-primary-light-yellow text-primary-yellow ${className}`}>
          {text}
      </div>
    )
  }
  return (
    <div className={`text-xs font-semibold px-3 py-2 w-fit rounded-2xl text-center 
    bg-primary-light-${color || "green"} text-primary-${color || "green"} ${className}`}>
        {text}
    </div>
  )
}

export default Pill