import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// imported react hooks and packages
import { CSSTransition } from 'react-transition-group';

// custom components
import Backdrop from '../Backdrop/Backdrop';
import Button from '../button/Button';
// import Switch from "react-switch";

// custom hooks
// import {useHttpClient} from '../../../../hooks/less-http-hook';
// import {AuthContext} from '../../../../context/auth-context';

// custom icons
import { VscChromeClose } from 'react-icons/vsc';

// custom css
import './Modal.css';
// import { images } from '../../../../assets'

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useApi } from '../../api/api-hook';
import { administratorEndpoints } from '../../api';
import Loader from '../../../../UI_Elements/loader/Loader';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("first name is required"),
  last_name: Yup.string().required("last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
});

const AddUserModalOverlay = props => {
    // const [toggle, setToggle] = useState({
    //     support: false,
    //     admin: false,
    //     disputes:false
    // })

    // const auth = useContext(AuthContext)
    // const { error, sendRequest} = useHttpClient();
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      };

  useEffect(() => {
    return
  }, [props.refresh])

  const {postData, error, isLoading} = useApi()

  // const handleSwitch = (type) =>{
  //   setToggle((prevState) => {
  //       const updatedToggle = {
  //           ...prevState,
  //           [type]: !prevState[type]
  //       };
  //       return updatedToggle;
  //     });
  // }

//   const handleSwitch = (type) =>{
//     setToggle((prevState) => {
//         const updatedToggle = {};
    
//         for (const key in prevState) {
//           if(prevState[key] === true && key === type){
//             updatedToggle[key] = false
//           }
//           else{
//             updatedToggle[key] = key === type
//           }
//         }
    
//         return updatedToggle;
//       });
//   }

  const handleSubmit = async(values, { setSubmitting }) => {
    console.log(values)
    

    try {

        const formData = new FormData();

        formData.append("first_name", values?.first_name )
        formData.append("last_name", values?.last_name )
        formData.append("email_address", values?.email )
        formData.append("phone_number", values?.phone )

        const responseData = await postData(administratorEndpoints['CREATE-ADMINISTRATOR'],
        formData, null)

        if(responseData.status === "success"){
          
            props.onSubmit()
        }
        

    } catch (err) {
        
        console.log(error)
    }

    props.onSubmit()

  };

  const content = (
    <div className={`dashboard-modal dashboard-modal-80 px-2 md:px-4
    ${isLoading && "dashboard-modal-loading"} 
    ${props.className} ${props.modalTopRight && 'dashboard-modal-top-right'}`}  
    style={props.style}>
      {/* <header className={`dashboard-modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header> */}
    <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <div className={`dashboard-modal__content ${props.contentClass}`}>
                <div className="flex items-start flex-col">

                    <h4 className='mb-4 text-primary-grey'>Invite a staff</h4>

                    <hr className='mb-4 w-full' style={{borderTop:"1px solid #E4E7EC !important"}}/>

                    <div className='w-full '>
                        <div>
                            <Field className="form__input font-sora w-full py-2 px-2" type="text" id="first_name" 
                            name="first_name" placeholder="Enter first name"/>
                            <ErrorMessage className="text-xs" name="first_name" component="div" />
                        </div>

                        <div className='my-4'>
                            <Field className="form__input font-sora w-full py-2 px-2" type="text" id="last_name" 
                            name="last_name" placeholder="Enter last name"/>
                            <ErrorMessage className="text-xs" name="last_name" component="div" />
                        </div>

                        <div className='my-4'>
                            <Field className="form__input font-sora w-full py-2 px-2" type="email" id="email" 
                            name="email" placeholder="Email address"/>
                            <ErrorMessage className="text-xs" name="email" component="div" />
                        </div>

                        <div className='my-4'>
                            <Field className="form__input font-sora w-full py-2 px-2" type="tel" id="phone" 
                            name="phone" placeholder="Phone"/>
                            <ErrorMessage className="text-xs" name="phone" component="div" />
                        </div>

                        
                    </div>

                    {/* <h3 className='my-4 text-primary-grey'>Grant access :</h3> */}

                    {/* <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 md:gap-10'>
                        <div className='flex items-center col-span-2'>
                            <Switch onChange={() => {return handleSwitch("support")}} 
                            checked={toggle.support} offColor="#E4E4E4" onColor='#2867F5' 
                            uncheckedIcon={false} checkedIcon={false} 
                            onHandleColor="#F2F4F7" offHandleColor="#F2F4F7" 
                            width={50} handleDiameter={22}
                            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)" />
                            <p className='text-secondary-grey ml-3'>Support</p>
                        </div>

                        <p className='text-secondary-grey text-sm col-span-3 mt-1 md:mt-0'>Allow users to view customers</p>
                    </div> */}

                    {/* <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 md:gap-8 my-4'>
                        <div className='flex items-center col-span-2'>
                            <Switch onChange={() => {return handleSwitch("admin")}} 
                            checked={toggle.admin} offColor="#E4E4E4" onColor='#2867F5' 
                            uncheckedIcon={false} checkedIcon={false} 
                            onHandleColor="#F2F4F7" offHandleColor="#F2F4F7" 
                            width={50} handleDiameter={22}
                            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)" />
                            <p className='text-secondary-grey ml-3'>Admin</p>
                        </div>

                        <p className='text-secondary-grey text-sm col-span-3 mt-1 md:mt-0'>Allow user to have admin access</p>
                    </div> */}

                    {/* <div className='grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5'>
                        <div className='flex items-center col-span-2'>
                            <Switch onChange={() => {return handleSwitch("disputes")}} 
                            checked={toggle.disputes} offColor="#E4E4E4" onColor='#2867F5' 
                            uncheckedIcon={false} checkedIcon={false} 
                            onHandleColor="#F2F4F7" offHandleColor="#F2F4F7" 
                            width={50} handleDiameter={22}
                            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)" />
                            <p className='text-secondary-grey ml-3 '>Disputes</p>
                        </div>

                        <p className='text-secondary-grey text-sm col-span-3 mt-1 md:mt-0'>Allow user to access dispute and reply</p>
                    </div>*/}
                </div> 
            {props.children}
            </div>
            <footer className={`dashboard-modal__footer w-full flex justify-end items-center ${props.footerClass}`}>
            <Button border shadow className={`${props.cancelClassName}`} onClick={props.onCancel}>Cancel</Button>
            <Button bgPrimary textWhite className={`ml-3 ${props.submitClassName}`}  
            disabled={isSubmitting} type="submit">Invite</Button>
            </footer>
            {isLoading && <Loader/>}
        </form>
    
    )}
      </Formik>

      {/* close button */}
      <div className={`absolute top-4 right-4 ${props.closeButton ? 'block':'hidden'}`}>
          <button onClick={props.onCancel}>
              <VscChromeClose
                  className='text-base text-dashboard-black rounded pointer'/>
          </button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const AddUserModal = props => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} fade={true}/>}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="dashboard-modal"
      >
        <AddUserModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default AddUserModal;
