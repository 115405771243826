import React, {useMemo, useEffect, useState, useContext} from 'react'
import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../../context/auth-context';
import moment from 'moment';


// DATA
import {DASHBOARD_COLUMNS} from '../../util/columns';


//custom components
import Loader from '../../../../UI_Elements/loader/Loader';


// custom icons
import {MdKeyboardArrowRight} from 'react-icons/md'

// custom css
import './tables.css'
import Pill from '../pills/Index';
import { useApi } from '../../api/api-hook';
import { transactionEndpoints } from '../../api';



function DashboardTable(props) {    

    const auth = useContext(AuthContext)

    const history = useNavigate()
    const {getData, error, isLoading} = useApi()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    // const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        setTableData(null)


        const fetchData = async() => {
            
            try {

                
                // const responseData = await 
                // sendRequest(`${process.env.REACT_APP_API_CALL}/event/list-all?page_num=${detailsData.current_page}&page_size=${detailsData.page_number}`, 
                // "GET", 
                // null , 
                // {Authorization: 'Bearer '+auth.token});

                const responseData = await getData(
                    transactionEndpoints['LIST-TRANSACTIONS-PAGINATED'], 
                    null, 
                    `?page_number=1&page_size=15`)

                setTableData(responseData.data)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data.transactions){
                        setCellData((cell)=>[...cell, {
                            "transactionId":file.id,
                            "eventName":file?.event?.name || "Not set",
                            "ticket":"Not set",
                            "reference": file.reference || "0",
                            // "ticket":Object.keys(file?.event?.availabilty).join(" | ") || "Not set",
                            "paid":file.amount || 0,
                            "commission":file.amount || 0,
                            "status":file.status,
                            "datePurchase": moment(file.createdAt).format('l')
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [getData, error]);


    const eventHandler = (id) => {
        history(`../transactions/${id}`)
    }



    const columns = useMemo(() => DASHBOARD_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>

        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__border mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4 px-6">{column.render('Header')}</th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='bg-odd-row font-sora'>
                                {row
                                    .cells
                                    .map(cell => {

                                        
                                        if(cell?.column?.id === "status" && cell?.value === "finalised"){
                                            return (
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                                    <Pill text={cell.value} color="green"/>
                                                </td>
                                            )
                                        }

                                        if(cell?.column?.id === "status" && cell?.value === "initiated"){
                                            return (
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                                    <Pill text={cell.value} color="red"/>
                                                </td>
                                            )
                                        }

                                        if(cell?.column?.id === "status" && cell?.value === "pending"){
                                            return (
                                                <td className="border-t-0 px-6 align-middle bohrder-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                                    <Pill text={cell.value} color="yellow"/>
                                                </td>
                                            )
                                        }
                                        
                                        return <td {...cell.getCellProps()} 
                                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-primary-grey">
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                
                                <td className='text-sm py-4 font-gilroy-bold whitespace-nowrap'>
                                    
                                    <MdKeyboardArrowRight className='text-primary-grey text-lg cursor-pointer' onClick={() =>eventHandler(row.original.id)}/>

                                </td>
                            </tr>
                        )
                    })}
                    {/* <tr className='bg-odd-row font-sora'>
                    <td className="border-t-0 px-6  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left ">
                        <p className='text-primary-grey'>#002292</p>
                    </td>

                    <td className="px-6 align-middle  text-sm whitespace-nowrap p-4">
                        <p className='text-primary-grey'>GTBANK Fashion Week</p>
                    </td>
                    

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        <p className='text-primary-grey'>Early Bird</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>₦ 5,000</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>₦ 100</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <Pill text="Successful" color="green"/>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>₦ 100</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        <MdKeyboardArrowRight className='text-primary-black text-lg cursor-pointer' onClick={eventHandler}/>
                    </td>

              </tr> */}
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            
            
        </div>
        }
        </>              
    )
}

export default DashboardTable