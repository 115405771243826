import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'


import Button from '../../components/button/Button';
import StaffTable from '../../components/tables/StaffTable';
import AddUserModal from '../../components/Modal/AddUser';
import NotificationModal from '../../components/Modal/NotificationModal';

function FirstSection() {
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddUser, setshowAddUser] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)

    const [refreshTable, setRefreshTable] = useState(false)

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
      };

    const handleShowAddUser = (type) =>{
        
        if(type){
            return setshowAddUser(true)
        }
        
        return setshowAddUser(false)
    }

    const handleNotification = () => {
        setshowAddUser(false)
        setRefreshTable(!refreshTable)
        return setOpenNotification(!openNotification)
    }

    return(
        <>
        <AddUserModal show={showAddUser} onCancel={() => handleShowAddUser(false)} onSubmit={handleNotification}/>
        <NotificationModal show={openNotification} 
        onCancel={handleNotification} 
        buttonClass="w-full bg-primary-blue text-white" 
        CancelText="OK" textWhite
        title="Successful"
        text="Invitation has been sent to user email address"/>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">

            <form onSubmit={handleSubmit}>
                <div className="flex items-center pl-2 search-payments bg-white ">
                    <RiSearchLine classname="text-lg  text-primary-grey"/>
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="p-3 rounded-lg outline-none border-none"
                    />
                </div>
                
            </form>

            <Button className="mt-2 md:mt-0" onClick={() => handleShowAddUser(true)} bgPrimary textWhite>
                + Add New
            </Button>

        </div>

        <StaffTable refresh={refreshTable} searchTerm={searchTerm}/>

        </>
    )
}

export default FirstSection