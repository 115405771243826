import { useHttpClient } from '../../../hooks/less-http-hook'
import { AuthContext } from '../../../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';


export const useStaff = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [statusLoading, setStatusLoading] = useState(isLoading)

    useEffect(() => {
        setStatusLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const updateStatus = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}admin/change-status`, "POST", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    

    return {statusLoading, updateStatus}
}