import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import * as functions from "../../../util/functions"

function PhotoCard(props) {
  return (
    <div className=' mt-2'>
        <div className='flex justify-between items-start'>
              <div>
                <p className='text-sm text-primary-black-70 font-semibold'>{props.file?.name || ""}</p>
                <p className='text-sm text-primary-black-70'>{functions.formatFileSize(parseFloat(props.file?.size) || 0)}</p>
              </div>

              <FiTrash2 onClick={props.onDelete} className='cursor-pointer'/>
          </div>

          {
            props.progress !== 0 && (
              <div className='flex items-center mt-2'>

                <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div class="bg-primary h-2.5 rounded-full" style={{width: `${props.progress || "0"}%`}}></div>
                </div>

                <p className='mx-3 '>{props.progress}%</p>
              </div>
            )
          }
    </div>
  )
}

export default PhotoCard