import React, {useMemo, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';


// DATA
import {BUSINESS_COLUMNS} from '../../util/columns';


//custom components
import Loader from '../../../../UI_Elements/loader/Loader';
import TablePagination from '../TablePagination/TablePagination';


// custom icons
import {MdKeyboardArrowRight} from 'react-icons/md'

// custom css
import './tables.css'
import Pill from '../pills/Index';
import { useApi } from '../../api/api-hook';
import { businessEndpoints } from '../../api';




function BusinessTable({searchTerm, type}) {    

    const history = useNavigate()
    const {getData, error, isLoading} = useApi()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : 1,
        "page_number":15,
        "status":"0",
        "search_text":searchTerm
    })


    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);


        const fetchData = async() => {
            
            try {
                const responseData = await getData(
                    businessEndpoints['LIST-BUSINESS-PAGINATED'], 
                    null, 
                    `?page_number=${detailsData.current_page}&page_size=${detailsData.page_number}`)

                    setTableData(responseData.data)

                    if(responseData){
                        setCellData([])
                        for(let file of responseData.data.organisers){
                            setCellData((cell)=>[...cell, {
                                "id":file.id,
                                "name": `${file?.first_name} ${file?.last_name}` || file.business_name || "Not set",
                                "type":"Not set",
                                "email": file.email_address || "Not set",
                                "phone":file.phone_number || "Not set",
                                "status":file.status,
                            }])
                        }
                    }

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [error, getData, detailsData]);





    const pageChangeHandler = (number) =>{
        setDetailsData({
            ...detailsData,
            "current_page":detailsData.current_page + number
        })
    }

    const pageSizeChangeHandler = () => {
        setDetailsData({
            ...detailsData,
            "page_number":detailsData.page_number + 10
        })
    }

    const eventHandler = (id) => {
        
        history(id)
        // setEventModal(!eventModal)
    }



    const columns = useMemo(() => BUSINESS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>


        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__border mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4 px-6">{column.render('Header')}</th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                        prepareRow(row)

                        if( row.values?.status !== type){
                            return false
                        }
                        return (
                            <tr {...row.getRowProps()} className='bg-odd-row font-sora'>
                                {row
                                    .cells
                                    .map(cell => {
                                        if(cell?.column?.id === "status"){
                                            let color = ""
                                            if(cell?.value === "active"){
                                                color = "green"
                                            }
                                            else if(cell?.value === "suspended"){
                                                color = "red"
                                            }
                                            else{
                                                color= "yellow"
                                            }
                                            return (
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                                    <Pill text={cell.value} color={color}/>
                                                </td>
                                            )
                                        }
                                        
                                        return <td {...cell.getCellProps()} 
                                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-primary-grey">
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                
                                <td className='text-sm py-4 font-gilroy-bold whitespace-nowrap'>
                                    
                                    <MdKeyboardArrowRight className='text-primary-grey text-lg cursor-pointer' onClick={() => eventHandler(row.original.id)}/>

                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {detailsData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                // pages = {pages}
                per_page = {detailsData.page_number}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default BusinessTable