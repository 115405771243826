import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import CheckComp from './components/CheckComp'
import ForgotComp from './components/ForgotComp'


function Forgotpassword() {
    const [check, setCheck] = useState(false)
    const [email, setEmail] = useState("")
    
    const checkHandler = () =>{
        setCheck(!check)
    }

    const emailHandler = (email_address) =>{
      setEmail(email_address)
  }

  return (
    <div className='auth_bg flex justify-center mt-16' data-aos="fade-left">
      <div className='mx-auto bg-white p-8 pb-4 mt-16 lg:mt-24 h-fit auth-box'>
        {!check ? <ForgotComp checkHandler={checkHandler} emailHandler={emailHandler}/> : <CSSTransition
        in={check}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="forgot"
      >
        <CheckComp email={email}/>
      </CSSTransition>}
      </div>
        
    </div>
  )
}

export default Forgotpassword