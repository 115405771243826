import React from 'react'

//custom icons
import DashboardTable from '../../components/tables/DashboardTable'
import Button from '../../components/button/Button'

function SecondSection() {
  return (
    <div className='mt-12'>
        <div className='flex flex-col md:flex-row gap-2 md:gap-0 justify-between items-start md:items-center'>
            <h3 className='font-semibold text-primary-grey'>Recent Transactions</h3>

            <Button to="/dashboard/transactions" className="text-primary font-semibold border-button-border border">
                View All
            </Button>
        </div>

        

        {/* transactions dashboard table */}
        <DashboardTable/>
    </div>
  )
}

export default SecondSection