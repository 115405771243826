import React, { useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import QRCode from 'react-qr-code'
import moment from 'moment'

//custom components
import { useApi } from '../../api/api-hook'
import {transactionEndpoints} from '../../api';


//sections
import HOC from '../../components/hoc/Hoc'
import Loader from '../../../../UI_Elements/loader/Loader'

//custom icons
import {IoChevronBack} from 'react-icons/io5'

//custom css
import './transactions.css'




function TransactionsDetails() {

  const [data, setData] = useState()
  const {id} = useParams()

  const {getData, error, isLoading} = useApi();

  useEffect(()=>{
    const fetchData = async() =>{

        try{
            
            const response = await getData(transactionEndpoints['TICKET-DETAILS'],null, `?transaction_id=${id || ""}`)

            if(response){
                setData(response?.data?.transaction)
            }
        }
        catch(err){
            console.log(error)
        }
    }

    fetchData();

  },[getData, error, id])

  const baseUrl = window.location.protocol + "//" + window.location.host;
  return (
    <>

    {isLoading && <Loader/>}
    
    {data && <HOC className='w-full min-h-screen bg-dashboard-bg'>

        

    <div className={`my-2 md:my-6 mb-8 md:mb-16 flex flex-col
        gap-2 items-start`}>
            <Link  to="/dashboard/transactions">
                <IoChevronBack/>
            </Link>
          <div>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-primary-grey'>
                Transaction details
            </h1>
            <p className='text-secondary-grey text-sm md:text-base '>View the transaction details</p>
          </div>
        </div>


        <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8 mx-auto'>
            <div className='bg-white ticket-bg p-6 md:px-8 md:col-span-1 lg:col-span-2 xl:col-span-2 '>
                <div className='flex justify-center items-center flex-col'>
                    <h2 className='text-black-text font-semibold text-xl mb-4'>Ticket Receipt</h2>
                    {/* <img src={images.ticketCode[0]} alt={images.ticketCode[1]} className='w-32 lg:w-40'/> */}
                    <div style={{ height: "auto", margin: "0 auto",}} className='w-32 lg:w-40'>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={
                            `${baseUrl}/ticket-validation?eventId=${data?.event?.id || ''}&event_type=${data?.event_type || ''}&ticket_id=${id || ''}`
                        }
                        viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>

                <div className=' my-12 md:my-16 border-t-2 border-dashed border-primary-dark relative'>
                    <div className={`border-2 border-primary-dark text-primary-dark px-6 py-2 
                    w-fit object__center bg-white rounded`}>
                        <p className='text-xxs sm:text-sm md:text-base'>Ticket 1 Of 1</p>
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-4'>Name</p>
                    <p className='font-bold text-black-text text-2xl'>{data?.owner?.first_name || "Not Set"}<br/>{data?.owner?.last_name || "Not Set"}</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Ticket/ seat</p>
                    <p className='font-semibold text-black-text text-base'>{data?.type || "Table for 4"}</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Event</p>
                    <p className='font-semibold text-black-text text-base lg:w-3/5'>{data?.event?.name || `Call for Designers & Exhibitors - 
                    Fashions Finest Africa EPIC SHOW`}</p>
                </div>

                <div className="flex flex-col sm:flex-row justify-start sm:justify-between gap-6 items-start  mb-8">
                    <div className=''>
                        <p className='font-light text-black-text mb-3'>Date</p>
                        <p className='font-semibold text-black-text text-base mb-2'>{moment(data?.event?.start_time).format('ddd, MMM D') || "Sat, Jun 10"} <br/>
                        {`Start at ${moment(data?.event?.start_time).format('h:mm a')}` || "Start at 2:00 PM"}</p>
                        {/* <p className='font-semibold text-primary text-base flex items-center gap-2'><img 
                        src={images.calendarIcon[0]} alt={images.calendarIcon[1]}/> Add to Calendar</p> */}
                    </div>

                    <div className=''>
                        <p className='font-light text-black-text mb-3'>Location</p>
                        <p className='font-semibold text-black-text text-base mb-2'>{data?.event?.address || ""} {data?.event?.state || ""}</p>
                        {/* <p className='font-semibold text-primary text-base flex items-center gap-2'><img 
                        src={images.mapPin[0]} alt={images.mapPin[1]}/> See Map</p> */}
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Order number</p>
                    <p className='font-semibold text-black-text text-base '>{data?.id || "Not Available"}</p>
                </div>

                <div className="flex flex-col sm:flex-row justify-start sm:justify-between gap-6 items-start sm:items-center mt-16">
                    <div className=''>
                        
                        <p className='font-semibold text-black-text text-2xl mb-2'>TOTAL</p>
                        
                    </div>

                    <div className=''>
                        <p className='font-semibold text-primary text-4xl'>₦{data?.amount || 0}</p>
                    </div>
                </div>

            </div>


           
        </div>
        
    </HOC>}
    </>
  )
}

export default TransactionsDetails