import React, {useEffect, useState } from 'react'

//custom icons
import ChartCard from '../../components/card/ChartCard'
import { useApi } from '../../api/api-hook';
import { transactionEndpoints, businessEndpoints } from '../../api';

function SecondSection() {
  const [graphDetails, setGraphDetails] = useState({
    revenue:[],
    businesses:[]
  })

  const {getData, error} = useApi()

  useEffect(()=>{
    const revenueHandler = async() => {
      try {

        const [revenueData, businessData] = await Promise.all([
          getData(transactionEndpoints['MONTHLY-REVENUE']),
          getData(businessEndpoints['MONTHLY-ORGANISERS'])

        ])

        if(revenueData){
          setGraphDetails((prevData) => {
            return {
              ...prevData,
              revenue:revenueData.data.monthly_revenue || []
            }
          })
        }

        if(businessData){
          
          setGraphDetails((prevData) => {
            return {
              ...prevData,
              businesses:businessData.data.monthly_organisers || []
            }
          })
        }
          

      } catch (err) {
          
          console.log(error)
      }

    };
    revenueHandler()
  },[error, getData])

  return (
    <div className='mt-12'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <ChartCard title="Businesses" areaChart data={graphDetails.businesses || []}/>

        <ChartCard title="Revenue" barChart data={graphDetails.revenue || []}/>
        </div>

    </div>
  )
}

export default SecondSection