import React, { useEffect, useState } from 'react'
import Backdrop from '../components/Backdrop/Backdrop';

import { sidebarItems } from './sidebarItems';
import { NavLink } from 'react-router-dom';
import { images } from '../../../assets';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const [mobile,
        setMobile] = useState(false);
    const [activeClass, setActiveClass] = useState(-1)

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800) {
                setMobile(true);
            } else {
                setMobile(false);
                setIsOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

  return (
    <>
       {mobile && isOpen &&  <Backdrop onClick={toggleSidebar}/>}
        <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
        onClick={toggleSidebar}
        >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isOpen ? '' : '-translate-x-full lg:translate-x-0'
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-primary-deep-blue">
            <img src={images.whiteLogo[0]} alt={images.whiteLogo[1]} className='p-6'/>
            <ul className="space-y-2 font-medium mt-6 mx-6">

                {
                    sidebarItems.map((item, index) => {
                        let Icon = item.icon
                        return (
                            <li key={index} className={`${activeClass === index ? 'navBgFilter':'navFilter'} p-3`}>
                                <NavLink exact onClick={toggleSidebar} to={item.path} 
                                className={({ isActive }) =>
                                isActive && setActiveClass(index)
                              } 
                               >
                                <div className="flex items-center ">
                                    <Icon className='text-white text-lg'/>
                                    
                                    <p className="ml-3 text-white">{item.name}</p>

                                    {activeClass === index && <div className='ml-auto py-4 px-1 rounded-lg bg-white'></div>}
                                </div>
                                </NavLink>
                            </li>
                        )
                    })
                }
                
                
            </ul>
        </div>
        </aside>
    </>
  )
}

export default Sidebar