import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// imported react hooks and packages
import { CSSTransition } from 'react-transition-group';

// custom components
import Backdrop from '../Backdrop/Backdrop';
import Button from '../button/Button';

// custom hooks

// custom icons
import { VscChromeClose } from 'react-icons/vsc';

// custom css
import './Modal.css';
// import { images } from '../../../../assets'

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  ticketName: Yup.string().required("ticket name is required"),
  quantity: Yup.string().required("quantity is required"),
  price: Yup.string().required("price is required"),
});

const AddTicketModalOverlay = props => {
    
    const initialValues = {
      ticketName: "",
      quantity: "",
      price: ""
      };

  useEffect(() => {
    return
  }, [props.refresh])


  const handleSubmit = async(values, { setSubmitting }) => {
    props.onSubmit(values, setSubmitting)
    props.onCancel()

  };

  const content = (
    <div className={`dashboard-modal dashboard-modal-80 px-2 md:px-4
    ${props.loading && "dashboard-modal-loading"} 
    ${props.className} ${props.modalTopRight && 'dashboard-modal-top-right'}`}  
    style={props.style}>
      {/* <header className={`dashboard-modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header> */}
    <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <div className={`dashboard-modal__content ${props.contentClass}`}>
                <div className="flex items-start flex-col">

                    <h4 className='mb-4 text-primary-grey'>Create ticket types</h4>
                    <p className='mb-4 text-primary-grey opacity-75'>Create ticket for your events and start selling</p>

                    <hr className='mb-4 w-full' style={{borderTop:"1px solid #E4E7EC !important"}}/>

                    <div className='w-full '>
                        <div>
                            <label htmlFor='ticketName' className='text-primary-black font-semibold'>Ticket name</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="text" id="ticketName" 
                            name="ticketName" placeholder="Enter name"/>
                            <ErrorMessage className="text-xs" name="ticketName" component="div" />
                        </div>

                        <div className='my-4'>
                            <label htmlFor='quantity' className='text-primary-black font-semibold'>Quantity</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="number" id="quantity" 
                            name="quantity" placeholder="Enter quantity"/>
                            <ErrorMessage className="text-xs" name="quantity" component="div" />
                        </div>

                        <div className='my-4'>
                        <label htmlFor='price' className='text-primary-black font-semibold'>Price</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="number" id="price" 
                            name="price" placeholder="0.00(naira)"/>
                            <ErrorMessage className="text-xs" name="price" component="div" />
                        </div>

                        
                    </div>

                    
                </div> 
            {props.children}
            </div>
            <footer className={`dashboard-modal__footer w-full flex justify-end items-center ${props.footerClass}`}>
            <Button border shadow className={`${props.cancelClassName}`} onClick={props.onCancel}>Cancel</Button>
            <Button bgPrimary textWhite className={`ml-3 ${props.submitClassName}`}  
            disabled={isSubmitting} type="submit">Save</Button>
            </footer>
        </form>
    
    )}
      </Formik>

      {/* close button */}
      <div className={`absolute top-4 right-4 ${props.closeButton ? 'block':'hidden'}`}>
          <button onClick={props.onCancel}>
              <VscChromeClose
                  className='text-base text-dashboard-black rounded pointer'/>
          </button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const AddTicketModal = props => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} fade={true}/>}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="dashboard-modal"
      >
        <AddTicketModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default AddTicketModal;
