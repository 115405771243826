export const businessEndpoints ={
      'SUSPEND-ORGANISER': 'administrator/suspend-organiser',
      'UNSUSPEND-ORGANISER': 'administrator/unsuspend-organiser',
      'APPROVE-ORGANISER': 'administrator/approve-organiser',
      'REJECT-ORGANISER': 'administrator/reject-organiser',
      'LIST-BUSINESS': 'administrator/list-organisers',
      'LIST-BUSINESS-PAGINATED': 'administrator/list-organisers-paginated',
      'SEARCH-ORGANISER': 'administrator/search-organisers',
      'MONTHLY-ORGANISERS': 'administrator/monthly-organisers',
      'COUNT-ORGANISERS': 'administrator/count-organisers',
      'ORGANISERS-DETAILS': 'organiser/details'
};

export const userEndpoints ={
    'LIST-USERS': 'administrator/list-users',
    'LIST-USERS-PAGINATED': 'administrator/list-users-paginated',
    'COUNT-USERS': 'administrator/count-users'
};

export const transactionEndpoints ={
    'LIST-TRANSACTIONS': 'administrator/list-transactions',
    'LIST-TRANSACTIONS-PAGINATED': 'administrator/list-transactions-paginated',
    'SEARCH-TRANSACTION': 'administrator/search-transactions',
    'MONTHLY-REVENUE':'administrator/monthly-revenue',
    'TICKET-DETAILS': 'transaction/details',
    'COUNT-TRANSACTIONS': 'administrator/count-transactions'
};

export const administratorEndpoints ={
    'CREATE-ADMINISTRATOR': 'administrator/create-administrator',
    'SEARCH-ADMINISTRATOR': 'administrator/search-administrators',
    'LIST-ADMINISTRATOR': 'administrator/list-administrators',
    'LIST-ADMINISTRATOR-PAGINATED': 'administrator/list-administrators-paginated',
    'COUNT-ADMINISTRATORS': 'administrator/count-administrators',
    'ADMINISTRATORS-DETAILS': 'administrator/details',
    'UPDATE-PASSWORD': 'administrator/update-password',
    'SUSPEND-ADMINISTRATOR': 'administrator/suspend-administrator',
    'UNSUSPEND-ADMINISTRATOR': 'administrator/unsuspend-administrator',
};

export const eventEndpoints = {
    'CREATE-EVENT': 'event/create',
    'CREATE-DRAFT': 'event/create-draft',
    'UPDATE-EVENT': 'event/update',
    'LIST-EVENT': 'event/list-all',
    'LIST-EVENT-PAGINATED': 'event/list-all-paginated',
    'LIST-UPCOMING-EVENT': 'event/list-upcoming',
    'LIST-UPCOMING-EVENT-PAGINATED': 'event/list-upcoming-paginated',
    'LIST-PAST-EVENT-PAGINATED': 'event/list-past-paginated',
    'EVENT-DETAILS': 'event/details',
    'SEARCH-EVENT': 'event/search',
    'UPLOAD-EVENT-IMAGE': 'event/upload-image'
  };